'use client';

import { useEffect, useState } from 'react';
import { Box, Stack } from '@mantine/core';
import { Plan, PlanSchedule, PlanScheduleItemType } from '@/types';
import { PlanStepDebtPaymentCard } from './PlanStepDebtPaymentCard';
import { PlanStepDebtPayoffCard } from './PlanStepDebtPayoffCard';
import { PlanStepDebtFreeCard } from './PlanStepDebtFreeCard';

export function PlanStepCards({
  plan,
  planSchedule,
  totalStepsCount,
}: {
  plan: Plan;
  planSchedule: PlanSchedule;
  totalStepsCount: number;
}) {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  useEffect(() => {
    setExpandedIndex(null);
  }, [planSchedule.length]);

  return (
    <Stack gap={0}>
      {planSchedule.map((item, index) => {
        let component: JSX.Element | null = null;

        const stepCount = planSchedule
          .slice(0, index + 1)
          .filter(
            (item) => item.type === PlanScheduleItemType.DebtPayment
          ).length;

        if (item.type === PlanScheduleItemType.DebtPayment) {
          const isExpanded = expandedIndex === index;
          component = (
            <PlanStepDebtPaymentCard
              plan={plan}
              item={item}
              stepCount={stepCount}
              totalStepsCount={totalStepsCount}
              setExpanded={(value) => setExpandedIndex(value ? index : null)}
              isExpanded={isExpanded}
            />
          );
        } else if (item.type === PlanScheduleItemType.DebtPayoff) {
          component = <PlanStepDebtPayoffCard plan={plan} item={item} />;
        } else if (item.type === PlanScheduleItemType.DebtFree) {
          component = <PlanStepDebtFreeCard item={item} />;
        }

        if (component) {
          return (
            <Stack key={index} gap={0}>
              {stepCount !== 1 && (
                <Box
                  h="lg"
                  style={{
                    borderLeft: '3px dotted var(--mantine-color-gray-4)',
                    width: '1px',
                    margin: '0 auto',
                  }}
                />
              )}
              <Box className="relative">{component}</Box>
            </Stack>
          );
        }

        return null;
      })}
    </Stack>
  );
}
