'use client';

import { Group, Text, Box, Button } from '@mantine/core';
import { useFinex } from '@/hooks';

export function ImpersonationHeader() {
  const { user } = useFinex();

  return (
    <Box bg="orange.1" h={30} style={{ borderBottom: '1px solid #ffc7b0' }}>
      <Group justify="center" h="100%">
        <Text size="xs" c="orange.9">
          You're current impersonating a user.
        </Text>
        <Button
          variant="transparent"
          size="xs"
          p={0}
          c="orange.9"
          onClick={() => {
            user.logout('/login');
          }}
        >
          Logout
        </Button>
      </Group>
    </Box>
  );
}
