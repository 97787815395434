import React from 'react';

const EmptyVideoGallery = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M39.6797 17.75L39.75 20L39.6797 38L33 33.3594V24.7109L39.6797 20V17.75C39.2578 17.8203 38.7656 17.9609 38.4141 18.1719L33 21.9688V21.125C33 19.2969 31.4531 17.75 29.625 17.75H12.75V14.375C12.75 12.5469 14.2266 11 16.125 11H27.9375C28.2188 11 28.5 10.7891 28.5 10.4375V9.3125C28.5 9.03125 28.2188 8.75 27.9375 8.75H16.125C12.9609 8.75 10.5 11.2812 10.5 14.375V17.75H9.375C7.47656 17.75 6 19.2969 6 21.125V36.875C6 38.7734 7.47656 40.25 9.375 40.25H29.625C31.4531 40.25 33 38.7734 33 36.875V36.1016L38.4141 39.8984C38.7656 40.1094 39.2578 40.25 39.6797 40.25C40.875 40.25 42 39.3359 42 38.0703V20C42 18.6641 40.875 17.75 39.6797 17.75ZM30.75 36.875C30.75 37.5078 30.1875 38 29.625 38H9.375C8.74219 38 8.25 37.5078 8.25 36.875V21.125C8.25 20.5625 8.74219 20 9.375 20H29.625C30.1875 20 30.75 20.5625 30.75 21.125V36.875ZM28.0078 22.25H10.9219C10.6406 22.3203 10.5 22.4609 10.5 22.7422V24.0781C10.5 24.3594 10.6406 24.5 10.9219 24.5H28.0078C28.2891 24.5 28.4297 24.3594 28.5 24.0781V22.7422C28.4297 22.4609 28.2891 22.3203 28.0078 22.25Z" />
    </svg>
  );
};

export default EmptyVideoGallery;
