import React from 'react';
import { useAmityElement } from '~/v4/core/hooks/uikit';
import { IconComponent } from '~/v4/core/IconComponent';

const DarkIllustrationSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="161"
    height="161"
    viewBox="0 0 161 161"
    fill="none"
  >
    <path
      d="M130.1 24.5H34.0996C29.6813 24.5 26.0996 28.0817 26.0996 32.5V128.5C26.0996 132.918 29.6813 136.5 34.0996 136.5H130.1C134.518 136.5 138.1 132.918 138.1 128.5V32.5C138.1 28.0817 134.518 24.5 130.1 24.5Z"
      fill="#292B32"
    />
    <path
      d="M43.7002 68.2H44.2002H151.7C152.628 68.2 153.519 68.5688 154.175 69.2251C154.831 69.8815 155.2 70.7718 155.2 71.7V91.7C155.2 92.6283 154.831 93.5185 154.175 94.1749C153.519 94.8313 152.628 95.2 151.7 95.2H43.7002C42.7719 95.2 41.8817 94.8313 41.2253 94.1749C40.5689 93.5185 40.2002 92.6283 40.2002 91.7V71.7C40.2002 70.7718 40.5689 69.8815 41.2253 69.2251C41.8817 68.5688 42.7719 68.2 43.7002 68.2Z"
      fill="#292B32"
      stroke="#40434E"
    />
    <path
      opacity="0.3"
      d="M90.9002 74.1H70.1002C68.7747 74.1 67.7002 75.1745 67.7002 76.5C67.7002 77.8255 68.7747 78.9 70.1002 78.9H90.9002C92.2257 78.9 93.3002 77.8255 93.3002 76.5C93.3002 75.1745 92.2257 74.1 90.9002 74.1Z"
      fill="#40434E"
    />
    <path
      opacity="0.15"
      d="M105.3 84.5H70.1002C68.7747 84.5 67.7002 85.5745 67.7002 86.9C67.7002 88.2255 68.7747 89.3 70.1002 89.3H105.3C106.626 89.3 107.7 88.2255 107.7 86.9C107.7 85.5745 106.626 84.5 105.3 84.5Z"
      fill="#40434E"
    />
    <path
      d="M53.6996 89.3C57.897 89.3 61.2996 85.8974 61.2996 81.7C61.2996 77.5027 57.897 74.1 53.6996 74.1C49.5022 74.1 46.0996 77.5027 46.0996 81.7C46.0996 85.8974 49.5022 89.3 53.6996 89.3Z"
      fill="#40434E"
    />
    <path
      d="M9.2998 102.6H9.7998H117.3C118.228 102.6 119.118 102.969 119.775 103.625C120.431 104.282 120.8 105.172 120.8 106.1V126.1C120.8 127.028 120.431 127.919 119.775 128.575C119.118 129.231 118.228 129.6 117.3 129.6H9.2998C8.37155 129.6 7.48131 129.231 6.82493 128.575C6.16855 127.919 5.7998 127.028 5.7998 126.1V106.1C5.7998 105.172 6.16855 104.282 6.82493 103.625C7.48131 102.969 8.37155 102.6 9.2998 102.6Z"
      fill="#292B32"
      stroke="#40434E"
    />
    <path
      opacity="0.3"
      d="M56.4998 108.5H35.6998C34.3743 108.5 33.2998 109.575 33.2998 110.9C33.2998 112.225 34.3743 113.3 35.6998 113.3H56.4998C57.8253 113.3 58.8998 112.225 58.8998 110.9C58.8998 109.575 57.8253 108.5 56.4998 108.5Z"
      fill="#40434E"
    />
    <path
      opacity="0.15"
      d="M70.8998 118.9H35.6998C34.3743 118.9 33.2998 119.975 33.2998 121.3C33.2998 122.626 34.3743 123.7 35.6998 123.7H70.8998C72.2253 123.7 73.2998 122.626 73.2998 121.3C73.2998 119.975 72.2253 118.9 70.8998 118.9Z"
      fill="#40434E"
    />
    <path
      d="M19.3002 123.7C23.4976 123.7 26.9002 120.297 26.9002 116.1C26.9002 111.903 23.4976 108.5 19.3002 108.5C15.1028 108.5 11.7002 111.903 11.7002 116.1C11.7002 120.297 15.1028 123.7 19.3002 123.7Z"
      fill="#40434E"
    />
    <path
      d="M9.2998 33.8H117.3C119.233 33.8 120.8 35.367 120.8 37.3V57.3C120.8 59.233 119.233 60.8 117.3 60.8H9.2998C7.36681 60.8 5.7998 59.233 5.7998 57.3V37.3C5.7998 35.367 7.36681 33.8 9.2998 33.8Z"
      fill="#292B32"
      stroke="#40434E"
    />
    <path
      opacity="0.3"
      d="M54.8992 39.7H34.0992C32.7737 39.7 31.6992 40.7745 31.6992 42.1C31.6992 43.4255 32.7737 44.5 34.0992 44.5H54.8992C56.2247 44.5 57.2992 43.4255 57.2992 42.1C57.2992 40.7745 56.2247 39.7 54.8992 39.7Z"
      fill="#40434E"
    />
    <path
      opacity="0.15"
      d="M69.2992 50.1H34.0992C32.7737 50.1 31.6992 51.1745 31.6992 52.5C31.6992 53.8255 32.7737 54.9 34.0992 54.9H69.2992C70.6247 54.9 71.6992 53.8255 71.6992 52.5C71.6992 51.1745 70.6247 50.1 69.2992 50.1Z"
      fill="#40434E"
    />
    <path
      d="M19.3002 54.9C23.4976 54.9 26.9002 51.4974 26.9002 47.3C26.9002 43.1026 23.4976 39.7 19.3002 39.7C15.1028 39.7 11.7002 43.1026 11.7002 47.3C11.7002 51.4974 15.1028 54.9 19.3002 54.9Z"
      fill="#40434E"
    />
  </svg>
);

const IllustrationSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="161"
    height="160"
    viewBox="0 0 161 160"
    fill="none"
  >
    <path
      d="M130.452 24H34.4521C30.0339 24 26.4521 27.5817 26.4521 32V128C26.4521 132.418 30.0339 136 34.4521 136H130.452C134.87 136 138.452 132.418 138.452 128V32C138.452 27.5817 134.87 24 130.452 24Z"
      fill="#EBECEF"
    />
    <path
      d="M44.0522 67.7H44.5522H152.052C152.981 67.7 153.871 68.0688 154.527 68.7251C155.184 69.3815 155.552 70.2718 155.552 71.2V91.2C155.552 92.1283 155.184 93.0185 154.527 93.6749C153.871 94.3313 152.981 94.7 152.052 94.7H44.0522C43.124 94.7 42.2337 94.3313 41.5774 93.6749C40.921 93.0185 40.5522 92.1283 40.5522 91.2V71.2C40.5522 70.2718 40.921 69.3815 41.5774 68.7251C42.2337 68.0688 43.124 67.7 44.0522 67.7Z"
      fill="white"
      stroke="#EBECEF"
    />
    <path
      opacity="0.3"
      d="M91.2523 73.6H70.4522C69.1268 73.6 68.0522 74.6745 68.0522 76C68.0522 77.3255 69.1268 78.4 70.4522 78.4H91.2523C92.5777 78.4 93.6523 77.3255 93.6523 76C93.6523 74.6745 92.5777 73.6 91.2523 73.6Z"
      fill="#A5A9B5"
    />
    <path
      opacity="0.15"
      d="M105.652 84H70.4522C69.1268 84 68.0522 85.0745 68.0522 86.4C68.0522 87.7255 69.1268 88.8 70.4522 88.8H105.652C106.978 88.8 108.052 87.7255 108.052 86.4C108.052 85.0745 106.978 84 105.652 84Z"
      fill="#A5A9B5"
    />
    <path
      d="M54.0521 88.8C58.2495 88.8 61.6521 85.3974 61.6521 81.2C61.6521 77.0027 58.2495 73.6 54.0521 73.6C49.8548 73.6 46.4521 77.0027 46.4521 81.2C46.4521 85.3974 49.8548 88.8 54.0521 88.8Z"
      fill="#A5A9B5"
    />
    <path
      d="M9.65234 102.1H10.1523H117.652C118.581 102.1 119.471 102.469 120.127 103.125C120.784 103.782 121.152 104.672 121.152 105.6V125.6C121.152 126.528 120.784 127.419 120.127 128.075C119.471 128.731 118.581 129.1 117.652 129.1H9.65234C8.72409 129.1 7.83385 128.731 7.17747 128.075C6.52109 127.419 6.15234 126.528 6.15234 125.6V105.6C6.15234 104.672 6.52109 103.782 7.17747 103.125C7.83385 102.469 8.72409 102.1 9.65234 102.1Z"
      fill="white"
      stroke="#EBECEF"
    />
    <path
      opacity="0.3"
      d="M56.8523 108H36.0523C34.7269 108 33.6523 109.075 33.6523 110.4C33.6523 111.725 34.7269 112.8 36.0523 112.8H56.8523C58.1778 112.8 59.2523 111.725 59.2523 110.4C59.2523 109.075 58.1778 108 56.8523 108Z"
      fill="#A5A9B5"
    />
    <path
      opacity="0.15"
      d="M71.2523 118.4H36.0523C34.7269 118.4 33.6523 119.475 33.6523 120.8C33.6523 122.126 34.7269 123.2 36.0523 123.2H71.2523C72.5778 123.2 73.6523 122.126 73.6523 120.8C73.6523 119.475 72.5778 118.4 71.2523 118.4Z"
      fill="#A5A9B5"
    />
    <path
      d="M19.6522 123.2C23.8496 123.2 27.2522 119.797 27.2522 115.6C27.2522 111.403 23.8496 108 19.6522 108C15.4549 108 12.0522 111.403 12.0522 115.6C12.0522 119.797 15.4549 123.2 19.6522 123.2Z"
      fill="#A5A9B5"
    />
    <path
      d="M9.65234 33.3H117.652C119.585 33.3 121.152 34.867 121.152 36.8V56.8C121.152 58.733 119.585 60.3 117.652 60.3H9.65234C7.71935 60.3 6.15234 58.733 6.15234 56.8V36.8C6.15234 34.867 7.71935 33.3 9.65234 33.3Z"
      fill="white"
      stroke="#EBECEF"
    />
    <path
      opacity="0.3"
      d="M55.2522 39.2H34.4522C33.1268 39.2 32.0522 40.2745 32.0522 41.6C32.0522 42.9255 33.1268 44 34.4522 44H55.2522C56.5777 44 57.6522 42.9255 57.6522 41.6C57.6522 40.2745 56.5777 39.2 55.2522 39.2Z"
      fill="#A5A9B5"
    />
    <path
      opacity="0.15"
      d="M69.6522 49.6H34.4522C33.1268 49.6 32.0522 50.6745 32.0522 52C32.0522 53.3255 33.1268 54.4 34.4522 54.4H69.6522C70.9777 54.4 72.0522 53.3255 72.0522 52C72.0522 50.6745 70.9777 49.6 69.6522 49.6Z"
      fill="#A5A9B5"
    />
    <path
      d="M19.6522 54.4C23.8496 54.4 27.2522 50.9974 27.2522 46.8C27.2522 42.6026 23.8496 39.2 19.6522 39.2C15.4549 39.2 12.0522 42.6026 12.0522 46.8C12.0522 50.9974 15.4549 54.4 19.6522 54.4Z"
      fill="#A5A9B5"
    />
  </svg>
);

interface IllustrationProps {
  pageId?: string;
  componentId?: string;
}

export const Illustration = ({ pageId = '*', componentId = '*' }: IllustrationProps) => {
  const elementId = 'illustration';
  const {
    currentTheme,
    accessibilityId,
    config,
    defaultConfig,
    isExcluded,
    uiReference,
    themeStyles,
  } = useAmityElement({
    pageId,
    componentId,
    elementId,
  });

  if (isExcluded) return null;

  return (
    <IconComponent
      defaultIconName={defaultConfig.icon}
      configIconName={config.icon}
      imgIcon={() => <img src={config.icon} alt={uiReference} data-qa-anchor={accessibilityId} />}
      defaultIcon={() => (
        <div data-qa-anchor={accessibilityId}>
          {currentTheme === 'light' ? <IllustrationSvg /> : <DarkIllustrationSvg />}
        </div>
      )}
    />
  );
};
