'use client';

import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { Button, Card, Container, Stack, Text, Title } from '@mantine/core';
import { CenteredLogo } from './CenteredLogo';

function ErrorInfoCard() {
  const searchParams = useSearchParams();
  const errorParam = searchParams.get('error');
  const errorCodeParam = searchParams.get('error_code');
  const errorDescriptionParam = searchParams.get('error_description');

  if (!errorParam && !errorCodeParam && !errorDescriptionParam) {
    return null;
  }

  return (
    <Card
      withBorder
      p="md"
      pt="sm"
      my="sm"
      radius="md"
      shadow="md"
      c="gray.7"
      fs="italic"
    >
      <Stack gap="sm">
        {errorParam ? (
          <Stack gap={0}>
            <Text size="sm" fw={600}>
              Error
            </Text>
            <Text size="sm">{errorParam}</Text>
          </Stack>
        ) : null}
        {errorCodeParam ? (
          <Stack gap={0}>
            <Text size="sm" fw={600}>
              Error code
            </Text>
            <Text size="sm">{errorCodeParam}</Text>
          </Stack>
        ) : null}
        {errorDescriptionParam ? (
          <Stack gap={0}>
            <Text size="sm" fw={600}>
              Error description
            </Text>
            <Text size="sm">{errorDescriptionParam}</Text>
          </Stack>
        ) : null}
      </Stack>
    </Card>
  );
}

export function ErrorPageContent({
  showErrorInfo = false,
}: {
  showErrorInfo?: boolean;
}) {
  return (
    <Container size="md" py="xl" ta="center" maw={500}>
      <Stack align="center" gap="xl">
        <CenteredLogo />
        <Stack align="center" gap="sm">
          <Title order={2} ta="center">
            Oh no! 😓
          </Title>
          <Stack gap="xs">
            <Text ta="center" lh={1.6}>
              Looks like an error occurred.
            </Text>

            {showErrorInfo && <ErrorInfoCard />}

            <Text ta="center" lh={1.6}>
              We&apos;re so sorry about that. Please try again or email us at{' '}
              <Link
                className="text-blue-900 hover:underline"
                href="mailto:support@cavernsoftware.com"
              >
                support@cavernsoftware.com
              </Link>{' '}
              if the problem persists.
            </Text>
          </Stack>
        </Stack>

        <Button size="md" component={Link} href="/">
          Go to home
        </Button>
      </Stack>
    </Container>
  );
}
