'use client';

import { useEffect } from 'react';
import { Button, Group, Stack, Text } from '@mantine/core';
import { useConfetti } from '@/hooks';
import { Modal } from '@/components';

interface CongratsPaymentModalProps {
  isOpened: boolean;
  onClose: () => void;
}

export function CongratsPaymentModal({
  isOpened,
  onClose,
}: CongratsPaymentModalProps) {
  const { stopConfetti, startConfetti } = useConfetti();

  useEffect(() => {
    if (isOpened) {
      startConfetti();
    }
  }, [isOpened]);

  const handleClose = () => {
    stopConfetti();
    onClose();
  };

  return (
    <Modal
      isOpened={isOpened}
      onClose={handleClose}
      title={
        <Text
          ta="center"
          component="span"
          w="100%"
          display="inline-block"
          fw={700}
          pl="lg"
          size="xl"
          c="green.9"
          lh={1.4}
        >
          You've completed
          <br />a payment!
        </Text>
      }
    >
      <Stack gap="xs" ta="center">
        <Group justify="center">
          <Text size="sm" lh={1.6} maw={200}>
            Congrats on moving one step closer to being debt-free! 🎉
          </Text>
        </Group>

        <Button color="green.8" mt={6} size="md" onClick={handleClose}>
          Amazing!
        </Button>
      </Stack>
    </Modal>
  );
}
