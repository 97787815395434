const ORIGINAL_WIDTH = 1024;
const ORIGINAL_HEIGHT = 712;
const ASPECT_RATIO = ORIGINAL_WIDTH / ORIGINAL_HEIGHT;

export function FinexLogo({ size = 40 }: { size?: number }) {
  const width = size;
  const height = size / ASPECT_RATIO;
  return (
    <svg
      aria-label="Finex Logo"
      width={width}
      height={height}
      viewBox={`0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width, height }}
    >
      <g clipPath="url(#clip0_1_2)">
        <mask
          id="mask0_1_2"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={ORIGINAL_WIDTH}
          height={ORIGINAL_HEIGHT}
        >
          <path
            d="M434.208 -7.62939e-06C397.162 -7.62939e-06 362.113 12.6113 328.063 30.2671C267.319 61.3749 215.402 126.113 197.326 193.163C187.657 228.474 190.18 275.767 202.581 296.155L206.574 302.671L214.351 290.27C226.752 270.092 251.555 251.175 279.299 240.665C309.987 229.315 312.089 229.105 536.99 229.105C712.287 229.105 747.388 228.474 764.203 225.952C847.858 212.29 935.506 146.501 998.142 50.2349C1008.44 34.2606 1026.15 -7.62939e-06 1023.79 -7.62939e-06C1021.42 -7.62939e-06 452.337 -7.62939e-06 434.208 -7.62939e-06Z"
            fill="black"
          />
          <path
            d="M270.261 318.435C164.747 336.09 79.8309 404.191 32.959 508.655C7.73641 564.355 -3.61375 626.57 1.01039 681.429C4.16321 717.582 4.3734 717.792 14.2522 700.556C38.634 658.309 75.2067 615.43 108.416 589.577C135.951 568.348 173.154 551.323 203.001 546.699C210.568 545.438 247.561 544.387 285.395 544.387C358.33 544.387 375.776 542.915 404.571 534.718C475.825 513.91 551.703 452.535 605.301 372.033C616.651 355.007 635.988 318.855 635.988 315.282C635.988 315.282 557.063 315.072 461.007 315.282C330.27 315.492 282.032 316.333 270.261 318.435Z"
            fill="black"
          />
        </mask>
        <g mask="url(#mask0_1_2)">
          <rect
            width={ORIGINAL_WIDTH}
            height={ORIGINAL_HEIGHT}
            fill="url(#paint0_linear_1_2)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1_2"
          x1="-1.1269e-07"
          y1={ORIGINAL_HEIGHT / 2}
          x2={ORIGINAL_WIDTH}
          y2={ORIGINAL_HEIGHT / 2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B800BC" />
          <stop offset="1" stopColor="#004EFF" />
        </linearGradient>
        <clipPath id="clip0_1_2">
          <rect width={ORIGINAL_WIDTH} height={ORIGINAL_HEIGHT} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
