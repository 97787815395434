import React from 'react';

export const VerifyBadgeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="9.88525" cy="10" r="5.5" fill="white" />
      <path
        d="M8.62899 17.3036C9.55401 18.2356 10.4373 18.2286 11.3693 17.3036L12.4125 16.2604C12.5099 16.163 12.5934 16.1352 12.7185 16.1352H14.193C15.5075 16.1352 16.1334 15.5092 16.1334 14.1947V12.7203C16.1334 12.5951 16.1682 12.5047 16.2586 12.4143L17.3019 11.3641C18.2339 10.439 18.2269 9.55575 17.3019 8.63073L16.2586 7.58748C16.1682 7.49011 16.1334 7.40665 16.1334 7.28146V5.807C16.1334 4.4925 15.5075 3.86655 14.193 3.86655H12.7185C12.5934 3.86655 12.5029 3.83178 12.4125 3.74136L11.3693 2.69811C10.4373 1.76614 9.55401 1.76614 8.62899 2.70507L7.58574 3.74136C7.49533 3.83178 7.40491 3.86655 7.27972 3.86655H5.80526C4.49076 3.86655 3.86481 4.47859 3.86481 5.807V7.28146C3.86481 7.40665 3.83004 7.49707 3.73962 7.58748L2.69637 8.63073C1.7644 9.55575 1.77136 10.439 2.69637 11.3641L3.73962 12.4143C3.83004 12.5047 3.86481 12.5951 3.86481 12.7203V14.1947C3.86481 15.5092 4.49076 16.1352 5.80526 16.1352H7.27972C7.40491 16.1352 7.48837 16.163 7.58574 16.2604L8.62899 17.3036ZM8.8446 13.5618C8.60117 13.5618 8.40643 13.4714 8.25342 13.3115L6.02782 10.8216C5.90959 10.6964 5.84699 10.5225 5.84699 10.3486C5.84699 9.93132 6.14606 9.63225 6.57727 9.63225C6.81374 9.63225 6.98761 9.70876 7.14062 9.87568L8.81678 11.7466L12.1691 6.96153C12.336 6.72506 12.5168 6.62769 12.795 6.62769C13.2263 6.62769 13.5323 6.92676 13.5323 7.33015C13.5323 7.46925 13.4766 7.64312 13.3723 7.78918L9.47055 13.2628C9.31059 13.4714 9.10194 13.5618 8.8446 13.5618Z"
        fill="#1054DE"
      />
    </svg>
  );
};
