import React from 'react';

const Happy = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Reactions" clipPath="url(#clip0_1709_1751)">
      <path
        id="Vector"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#FFD54F"
      />
      <path
        id="Vector_2"
        d="M27.2933 19.9601C26.9867 20.9334 26.5867 21.8267 26.0667 22.6667H5.93332C5.41332 21.8267 5.01332 20.9334 4.70665 19.9601C4.61332 19.6534 4.66665 19.3334 4.86665 19.0801C5.05332 18.8134 5.34665 18.6667 5.66665 18.6667H26.3333C26.6533 18.6667 26.9467 18.8134 27.1333 19.0801C27.3333 19.3334 27.3867 19.6534 27.2933 19.9601Z"
        fill="white"
      />
      <path
        id="Vector_3"
        d="M26.0664 22.6667C23.9598 26.1201 20.1864 28.2934 15.9998 28.2934C11.8131 28.2934 8.03977 26.1201 5.93311 22.6667H26.0664Z"
        fill="#E53935"
      />
      <path
        id="Vector_4"
        d="M12.3332 13.6668C11.7812 13.6668 11.3332 13.2188 11.3332 12.6668C11.3332 11.9322 10.7358 11.3335 9.99984 11.3335C9.26384 11.3335 8.6665 11.9322 8.6665 12.6668C8.6665 13.2188 8.2185 13.6668 7.6665 13.6668C7.1145 13.6668 6.6665 13.2188 6.6665 12.6668C6.6665 10.8295 8.16117 9.3335 9.99984 9.3335C11.8385 9.3335 13.3332 10.8295 13.3332 12.6668C13.3332 13.2188 12.8852 13.6668 12.3332 13.6668Z"
        fill="#6D4C41"
      />
      <path
        id="Vector_5"
        d="M24.3332 13.6668C23.7812 13.6668 23.3332 13.2188 23.3332 12.6668C23.3332 11.9322 22.7358 11.3335 21.9998 11.3335C21.2638 11.3335 20.6665 11.9322 20.6665 12.6668C20.6665 13.2188 20.2185 13.6668 19.6665 13.6668C19.1145 13.6668 18.6665 13.2188 18.6665 12.6668C18.6665 10.8295 20.1612 9.3335 21.9998 9.3335C23.8385 9.3335 25.3332 10.8295 25.3332 12.6668C25.3332 13.2188 24.8852 13.6668 24.3332 13.6668Z"
        fill="#6D4C41"
      />
    </g>
    <defs>
      <clipPath id="clip0_1709_1751">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Happy;
