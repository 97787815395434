'use client';

import { Button, Stack, Select, NumberInput, Group } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { startOfMonth } from 'date-fns';
import { IconTrash } from '@tabler/icons-react';
import {
  PlannedExtraDebtPayment,
  PlannedExtraDebtPaymentFrequencyType,
  plannedExtraDebtPaymentFrequencyTypeLabel,
} from '@/types';
import { formatDateToISO, parseISOToDate, setNullToUndefined } from '@/utils';

interface AddPlannedExtraDebtPaymentModalProps {
  initialValues?: Partial<Omit<PlannedExtraDebtPayment, 'id'>>;
  onSubmit: (debt: Omit<PlannedExtraDebtPayment, 'id'>) => void;
  onCancel: () => void;
  onDelete?: () => void;
  submitLabel?: string;
  isLoading?: boolean;
}

export function PlannedExtraDebtPaymentForm({
  initialValues = {
    frequencyType: PlannedExtraDebtPaymentFrequencyType.Monthly,
  },
  onSubmit,
  onCancel,
  onDelete,
  submitLabel = 'Submit',
  isLoading,
}: AddPlannedExtraDebtPaymentModalProps) {
  const initialFormValues = {
    ...initialValues,
    amount: setNullToUndefined(initialValues.amount),
    startDate: parseISOToDate(initialValues.startDate),
    endDate: parseISOToDate(initialValues.endDate),
    frequencyType:
      initialValues.frequencyType ||
      PlannedExtraDebtPaymentFrequencyType.Monthly,
    intervalDays: setNullToUndefined(initialValues.intervalDays),
  };

  const form = useForm({
    initialValues: initialFormValues,
    validate: {
      amount: (value) => !value && 'Amount is required',
      startDate: (value) => !value && 'Start date is required',
      frequencyType: (value) => !value && 'Frequency type is required',
      intervalDays: (value) => {
        if (
          form.values.frequencyType ===
          PlannedExtraDebtPaymentFrequencyType.DayInterval
        ) {
          if (!value || (typeof value === 'number' && value < 1)) {
            return 'Payment interval must be at least 1 day';
          }
        }
        return null;
      },
    },
  });

  const handleSubmit = form.onSubmit((values) => {
    const submissionValues = {
      ...values,
      frequencyType: values.frequencyType,
      intervalDays: values.intervalDays,
      amount: values.amount,
      startDate: formatDateToISO(values.startDate, false),
      endDate: formatDateToISO(values.endDate, false),
    };
    onSubmit(submissionValues as Omit<PlannedExtraDebtPayment, 'id'>);
  });

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Stack>
        <NumberInput
          label="Amount"
          placeholder="0.00"
          prefix="$"
          decimalScale={2}
          min={0}
          thousandSeparator=","
          fixedDecimalScale
          required
          inputMode="decimal"
          autoComplete="off"
          {...form.getInputProps('amount')}
        />

        <Select
          label="Frequency"
          placeholder="Select frequency"
          comboboxProps={{ zIndex: 1000 }}
          data={[
            {
              label:
                plannedExtraDebtPaymentFrequencyTypeLabel[
                  PlannedExtraDebtPaymentFrequencyType.Monthly
                ],
              value: PlannedExtraDebtPaymentFrequencyType.Monthly,
            },
            // {
            //   label:
            //     plannedExtraDebtPaymentFrequencyTypeLabel[
            //       PlannedExtraDebtPaymentFrequencyType.DayInterval
            //     ],
            //   value: PlannedExtraDebtPaymentFrequencyType.DayInterval,
            // },
            {
              label:
                plannedExtraDebtPaymentFrequencyTypeLabel[
                  PlannedExtraDebtPaymentFrequencyType.OneTime
                ],
              value: PlannedExtraDebtPaymentFrequencyType.OneTime,
            },
          ]}
          required
          autoComplete="off"
          {...form.getInputProps('frequencyType')}
        />

        <MonthPickerInput
          label={
            form.values.frequencyType ===
            PlannedExtraDebtPaymentFrequencyType.OneTime ? (
              <>Month</>
            ) : (
              <>Starting month</>
            )
          }
          placeholder={`Select a ${
            form.values.frequencyType ===
            PlannedExtraDebtPaymentFrequencyType.OneTime
              ? 'month'
              : 'starting month'
          }`}
          defaultDate={startOfMonth(new Date())}
          popoverProps={{ zIndex: 1000 }}
          valueFormat="MMM YYYY"
          required
          inputMode="none"
          {...form.getInputProps('startDate')}
        />

        {form.values.frequencyType !==
          PlannedExtraDebtPaymentFrequencyType.OneTime && (
          <MonthPickerInput
            label="Ending month"
            placeholder="Select the ending month"
            description="Leave this blank if this is recurring every month after the starting month."
            defaultDate={startOfMonth(new Date())}
            popoverProps={{ zIndex: 1000 }}
            valueFormat="MMM YYYY"
            inputMode="none"
            clearable
            {...form.getInputProps('endDate')}
          />
        )}

        {form.values.frequencyType ===
        PlannedExtraDebtPaymentFrequencyType.DayInterval ? (
          <NumberInput
            label="Interval Days"
            placeholder="Enter number of days"
            min={1}
            required
            inputMode="decimal"
            autoComplete="off"
            {...form.getInputProps('intervalDays')}
          />
        ) : null}

        <Group
          justify={onDelete ? 'space-between' : 'flex-end'}
          align="center"
          mt="md"
          w="100%"
        >
          {onDelete && (
            <Button
              variant="subtle"
              onClick={onDelete}
              py={4}
              px="xs"
              color="red.4"
            >
              <IconTrash size={24} />
            </Button>
          )}

          <Group justify="flex-end">
            <Button variant="light" onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>
            <Button type="submit" loading={isLoading}>
              {submitLabel}
            </Button>
          </Group>
        </Group>
      </Stack>
    </form>
  );
}
