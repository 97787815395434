import Script from 'next/script';

declare global {
  interface Window {
    Rewardful?: {
      referral?: string;
      affiliate?: false | Record<string, unknown>;
    };
    rewardful?: (
      event: string,
      arg: ((referral: string) => void) | string
    ) => void;
  }
}

export function Rewardful(): JSX.Element {
  return (
    <>
      <Script src="https://r.wdfl.co/rw.js" data-rewardful="ad58ac" />
      <Script id="rewardful-queue" strategy="beforeInteractive">
        {`
          (function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');
        `}
      </Script>
    </>
  );
}
