'use client';

import { useState } from 'react';
import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { PlannedExtraDebtPayment } from '@/types';
import { useFinex } from '@/hooks';
import { Modal, PlannedExtraDebtPaymentForm } from '@/components';

interface EditPlannedExtraDebtPaymentModalProps {
  payment?: PlannedExtraDebtPayment;
  isOpened: boolean;
  onClose: () => void;
}

export function EditPlannedExtraDebtPaymentModal({
  payment,
  isOpened,
  onClose,
}: EditPlannedExtraDebtPaymentModalProps) {
  const { plans } = useFinex();
  const [error, setError] = useState<string | null>();

  const handleClose = () => {
    setError(null);
    onClose();
  };

  const handleSubmit = async (
    updatedPayment: Omit<PlannedExtraDebtPayment, 'id'>
  ) => {
    setError(null);
    if (payment?.id) {
      const res = await plans.updatePlannedExtraDebtPayment(payment.id, {
        ...updatedPayment,
      });
      if (res.error) {
        setError(res.error);
        return;
      }
    }
    handleClose();
  };

  const handleDelete = () => {
    if (payment) {
      const modalId = modals.openConfirmModal({
        title: 'Delete planned extra debt payment',
        centered: true,
        children: (
          <Text size="sm">
            Are you sure you want to delete this planned extra debt payment?
          </Text>
        ),
        labels: { confirm: 'Delete', cancel: 'Cancel' },
        confirmProps: {
          color: 'red',
          onClick: async () => {
            const res = await plans.deletePlannedExtraDebtPayment(payment.id);
            if (res.error) {
              return;
            }
            handleClose();
          },
        },
      });
    }
  };

  return (
    <Modal
      isOpened={isOpened}
      onClose={handleClose}
      title="Edit planned extra debt payment"
      error={error}
    >
      <PlannedExtraDebtPaymentForm
        initialValues={payment}
        onSubmit={handleSubmit}
        onCancel={handleClose}
        onDelete={handleDelete}
        submitLabel="Save"
        isLoading={plans.isLoading}
      />
    </Modal>
  );
}
