import { useAmityUser } from 'finex-amity-uikit';
import {
  useFinex,
  useUpdateAmityUserDebtFreeDate,
  useUpdateAmityUserProfile,
  useUpdateGleapUser,
} from '@/hooks';

export function FinexAmityProvider({
  children,
}: {
  readonly children?: React.ReactNode;
}): JSX.Element | null {
  const { user, profile } = useFinex();
  const amityUser = useAmityUser(user.data?.id);

  useUpdateAmityUserDebtFreeDate({
    profile,
    amityUser,
  });
  useUpdateAmityUserProfile({
    user,
    profile,
    amityUser,
  });
  useUpdateGleapUser({
    user,
    profile,
    amityUser,
  });

  return <>{children}</>;
}
