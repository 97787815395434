'use client';

import { useEffect, useState } from 'react';
import { Stack, Transition, Box } from '@mantine/core';
import { Loader, LoaderProps } from './Loader';

interface LoaderTransitionProps {
  showInitialLoading?: boolean;
  isLoading?: boolean;
  loaderProps?: LoaderProps;
  children?: React.ReactNode;
  height?: number;
}

export function LoaderTransition({
  showInitialLoading = true,
  isLoading = false,
  loaderProps,
  children,
  height,
}: LoaderTransitionProps) {
  const [showLoader, setShowLoader] = useState(showInitialLoading);
  const [showContent, setShowContent] = useState(!showInitialLoading);

  useEffect(() => {
    if (!isLoading) {
      // When data arrives, fade out loader then fade in content
      const timer = setTimeout(() => {
        setShowLoader(false);
        setTimeout(() => setShowContent(true), 200);
      }, 200);
      return () => clearTimeout(timer);
    } else {
      // When data is removed, fade out content then fade in loader
      setShowContent(false);
      setTimeout(() => setShowLoader(true), 200);
    }
  }, [isLoading]);

  return (
    <Stack h={height} justify="center" align="center">
      <Transition
        mounted={showContent}
        transition="fade"
        duration={200}
        timingFunction="ease"
      >
        {(styles) => <Box style={styles}>{children}</Box>}
      </Transition>
      <Transition
        mounted={showLoader}
        transition="fade"
        duration={200}
        timingFunction="ease"
      >
        {(styles) => (
          <Loader {...loaderProps} size={Math.max((height ?? 0) - 8, 0)} />
        )}
      </Transition>
    </Stack>
  );
}
