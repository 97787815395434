import React from 'react';

const Crying = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1709_7904)">
      <path
        d="M16 32.5C24.8366 32.5 32 25.3366 32 16.5C32 7.66344 24.8366 0.5 16 0.5C7.16344 0.5 0 7.66344 0 16.5C0 25.3366 7.16344 32.5 16 32.5Z"
        fill="#FFD54F"
      />
      <path
        d="M9.33317 15.1667C10.8059 15.1667 11.9998 13.9728 11.9998 12.5C11.9998 11.0273 10.8059 9.83337 9.33317 9.83337C7.86041 9.83337 6.6665 11.0273 6.6665 12.5C6.6665 13.9728 7.86041 15.1667 9.33317 15.1667Z"
        fill="#6D4C41"
      />
      <path
        d="M22.6667 15.1667C24.1394 15.1667 25.3333 13.9728 25.3333 12.5C25.3333 11.0273 24.1394 9.83337 22.6667 9.83337C21.1939 9.83337 20 11.0273 20 12.5C20 13.9728 21.1939 15.1667 22.6667 15.1667Z"
        fill="#6D4C41"
      />
      <path
        d="M21.0248 27.3372C20.674 27.3372 20.3232 27.2038 20.0564 26.9352C17.8894 24.7682 14.1108 24.7682 11.9456 26.9352C11.4103 27.4705 10.5423 27.4705 10.007 26.9352C9.47163 26.4016 9.47163 25.5319 10.007 24.9966C11.6076 23.396 13.7362 22.5153 16.0001 22.5153C18.264 22.5153 20.3926 23.396 21.9932 24.9984C22.5286 25.5337 22.5286 26.4016 21.9932 26.937C21.7264 27.2038 21.3756 27.3372 21.0248 27.3372Z"
        fill="#6D4C41"
      />
      <path
        d="M24.8228 16.5987C24.4495 16.0587 23.5508 16.0587 23.1775 16.5987C22.6228 17.4014 21.3335 19.3881 21.3335 20.5001C21.3335 21.9707 22.5295 23.1667 24.0002 23.1667C25.4708 23.1667 26.6668 21.9707 26.6668 20.5001C26.6668 19.3881 25.3775 17.4014 24.8228 16.5987Z"
        fill="#00BCD4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1709_7904">
        <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default Crying;
