'use client';

import dynamic from 'next/dynamic';
import { subMonths } from 'date-fns';
import { Card, Box, useMantineTheme, Title } from '@mantine/core';
import { formatCurrency } from '@/utils';
import { usePreferencesStore } from '@/stores';
import { PlanScheduleItem } from '@/types';

const ReactApexChart = dynamic(() => import('react-apexcharts'), {
  ssr: false,
});

interface PayoffTimelineChartProps {
  debtPayments: PlanScheduleItem[];
}

function groupDebtPaymentsByMonth(payments: PlanScheduleItem[]) {
  const monthlyPayments = new Map<string, PlanScheduleItem>();

  payments.forEach((payment) => {
    const monthKey = `${payment.date.getFullYear()}-${payment.date.getMonth()}`;
    const existingPayment = monthlyPayments.get(monthKey);

    if (!existingPayment) {
      monthlyPayments.set(monthKey, payment);
    } else {
      // Aggregate payments within the same month
      monthlyPayments.set(monthKey, {
        ...payment,
        totalPayment:
          (payment.totalPayment || 0) + (existingPayment.totalPayment || 0),
        principal: (payment.principal || 0) + (existingPayment.principal || 0),
        interest: (payment.interest || 0) + (existingPayment.interest || 0),
        // Use the last balance after for the month
        balanceBefore:
          (payment.balanceBefore || 0) + (existingPayment.balanceBefore || 0),
        balanceAfter:
          (payment.balanceAfter || 0) + (existingPayment.balanceAfter || 0),
      });
    }
  });

  return Array.from(monthlyPayments.values()).sort(
    (a, b) => a.date.getTime() - b.date.getTime()
  );
}

export function PayoffTimelineChart({
  debtPayments,
}: PayoffTimelineChartProps) {
  const theme = useMantineTheme();
  const { colorScheme } = usePreferencesStore();
  const textColor =
    colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[9];

  if (!debtPayments.length) return null;

  const debtPaymentsByMonth = groupDebtPaymentsByMonth(debtPayments);

  // Use nested payments for non-monthly frequencies
  const chartData = [
    ...(debtPaymentsByMonth[0]?.date
      ? [
          {
            date: subMonths(debtPaymentsByMonth[0]?.date, 1),
            remainingBalance: debtPaymentsByMonth[0]?.balanceBefore || 0,
            interest: 0,
            payment: 0,
          },
        ]
      : []),
    ...debtPaymentsByMonth.flatMap((item) => [
      {
        date: item.date,
        remainingBalance: item.balanceAfter,
        interest: item.interest,
        payment: item.totalPayment,
      },
    ]),
  ];

  const series = [
    {
      name: 'Balance',
      data: chartData.map((payment) => ({
        x: payment.date.getTime(),
        y: payment.remainingBalance,
      })),
    },
    {
      name: 'Interest',
      data: chartData.map((payment) => ({
        x: payment.date.getTime(),
        y: payment.interest,
      })),
    },
    {
      name: 'Payment',
      data: chartData.map((payment) => ({
        x: payment.date.getTime(),
        y: payment.payment,
      })),
    },
  ];

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'area',
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      background: 'transparent',
      zoom: {
        enabled: false,
      },
    },
    colors: [
      theme.colors.blue[6],
      theme.colors.yellow[6],
      theme.colors.green[6],
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 100],
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          colors: textColor,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value: number) => formatCurrency(value),
        style: {
          colors: [textColor],
        },
      },
    },
    legend: {
      markers: {
        offsetX: -5,
      },
      itemMargin: {
        horizontal: 15,
      },
      offsetY: 9,
    },
    tooltip: {
      x: {
        format: 'MMM d, yyyy',
      },
      y: {
        formatter: (value: number) => formatCurrency(value),
      },
    },
    theme: {
      mode: colorScheme,
    },
  };

  return (
    <Card withBorder w="100%" radius="md" shadow="md">
      <Title fw={500} order={4}>
        Payoff Timeline
      </Title>
      <Box
        style={{ position: 'relative', left: -16, width: 'calc(100% + 32px)' }}
        h={300}
      >
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height="100%"
        />
      </Box>
    </Card>
  );
}
