import React from 'react';

function Fire(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <circle cx="10" cy="10" r="10" fill="url(#paint0_linear_5915_5783)"></circle>
      <path
        fill="#ED694A"
        d="M13.673 6.119c-.334-.364-.852-.855-1.073.254-.075.377-.25.668-.4.902-.162-.819-.649-1.667-1.108-2.238-.171-.214-.78-1.065-.898-2.487a.256.256 0 00-.427-.169C8.26 3.75 7.433 5.631 7.402 7.75c0 0-.628-.53-.969-1.513-.092-.265-.447-.316-.601-.082-.03.045-.057.09-.082.134-1.163 2.057-1.722 4.554-1.205 6.877.865 3.89 6.585 4.978 9.613 2.533 2.962-2.393 2.02-6.846-.485-9.58z"
      ></path>
      <path
        fill="#F4A32C"
        d="M12.758 8.963c-.246-.269-.63-.632-.792.188a1.9 1.9 0 01-.296.666c-.12-.605-.48-1.231-.818-1.653-.127-.158-.577-.786-.663-1.837a.189.189 0 00-.316-.125c-1.113 1.01-1.724 2.4-1.747 3.966 0 0-.464-.391-.715-1.118-.068-.196-.33-.233-.444-.06a1.736 1.736 0 00-.06.099c-.86 1.519-1.273 3.363-.891 5.079.639 2.873 4.863 3.677 7.1 1.87 2.188-1.766 1.493-5.055-.358-7.075z"
      ></path>
      <path
        fill="#F4D44E"
        d="M11.922 11.56c-.166-.183-.426-.428-.536.127a1.284 1.284 0 01-.2.45c-.081-.409-.325-.833-.554-1.119-.086-.107-.39-.532-.449-1.243a.128.128 0 00-.214-.084c-.753.684-1.167 1.625-1.182 2.684 0 0-.314-.265-.484-.757-.046-.132-.224-.157-.301-.04a1.184 1.184 0 00-.041.067c-.581 1.028-.861 2.276-.603 3.438.433 1.945 3.293 2.489 4.807 1.266.518-.418.84-1.008.905-1.66.098-.99-.282-2.185-1.148-3.13z"
      ></path>
      <path
        fill="#EAE9E8"
        d="M11.15 13.969c-.094-.101-.238-.238-.3.07a.717.717 0 01-.111.252 1.558 1.558 0 00-.309-.624 1.293 1.293 0 01-.25-.692.071.071 0 00-.119-.047c-.42.38-.65.905-.659 1.495 0 0-.174-.147-.27-.421-.025-.074-.124-.088-.167-.023a.628.628 0 00-.023.037c-.324.573-.48 1.269-.335 1.916.328 1.476 3.017 1.431 3.182-.22.054-.55-.158-1.217-.64-1.743z"
      ></path>
      <path
        fill="#F7E7A1"
        d="M9.231 16.225c-.144-.58.012-1.201.336-1.714a.585.585 0 01.022-.033c.043-.059.142-.046.168.02.095.245.27.377.27.377.007-.434.164-.827.453-1.143-.065-.09-.263-.305-.3-.757a.071.071 0 00-.119-.047c-.42.38-.65.905-.659 1.495 0 0-.174-.147-.27-.421-.025-.074-.124-.088-.167-.023a.628.628 0 00-.023.037c-.324.573-.48 1.269-.335 1.916.133.6.68.96 1.286 1.047-.327-.16-.578-.414-.662-.754z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_5915_5783"
          x1="4.5"
          x2="15.5"
          y1="4.5"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEC8A"></stop>
          <stop offset="1" stopColor="#FCCF5A"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Fire;
