'use client';

import { useMemo } from 'react';
import {
  IconCalendar,
  IconCash,
  IconSquareRoundedPercentage,
} from '@tabler/icons-react';
import { format } from 'date-fns';
import { useLatestDebtStats, usePayoffStats, usePlanSchedule } from '@/hooks';
import { Debt, Plan } from '@/types';
import { StatsCards } from './StatsCards';
import { formatCurrency } from '@/utils';

export function DebtStatsCards({ plan, debt }: { plan?: Plan; debt?: Debt }) {
  const planSchedule = usePlanSchedule(plan);
  const stats = usePayoffStats(planSchedule, debt);
  const debtStats = useLatestDebtStats(debt);

  const items = useMemo(
    () => [
      {
        id: 'balance',
        Icon: IconCash,
        title: 'Current balance',
        value: debtStats.currentBalance
          ? formatCurrency(debtStats.currentBalance)
          : '--',
        subtitle: debtStats.initialBalance
          ? `Starting from ${formatCurrency(debtStats.initialBalance)}`
          : '--',
      },
      {
        id: 'countdown',
        Icon: IconCalendar,
        title: 'Payoff countdown',
        value: stats.payoffCountdown
          ? `${stats.payoffCountdown?.toLocaleString()} days left`
          : '--',
        subtitle: stats.payoffDate
          ? format(stats.payoffDate, 'MMMM d, yyyy')
          : '--',
      },
      {
        id: 'interest',
        Icon: IconSquareRoundedPercentage,
        title: 'Total interest',
        value:
          stats.totalInterest || stats.totalInterest === 0
            ? formatCurrency(stats.totalInterest)
            : '--',
        subtitle:
          stats.totalPayments || stats.totalPayments === 0
            ? `Projected after ${stats.totalPayments} payments`
            : '--',
      },
    ],
    [stats, debtStats]
  );

  return <StatsCards items={items} />;
}
