import React from 'react';

export const People = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="39"
      viewBox="0 0 64 39"
      fill="none"
      {...props}
    >
      <path
        d="M32 0.590942C34.4754 0.590942 36.8493 1.5527 38.5997 3.26463C40.35 4.97656 41.3333 7.29843 41.3333 9.71947C41.3333 12.1405 40.35 14.4624 38.5997 16.1743C36.8493 17.8862 34.4754 18.848 32 18.848C29.5246 18.848 27.1507 17.8862 25.4003 16.1743C23.65 14.4624 22.6667 12.1405 22.6667 9.71947C22.6667 7.29843 23.65 4.97656 25.4003 3.26463C27.1507 1.5527 29.5246 0.590942 32 0.590942ZM13.3333 7.11132C14.8267 7.11132 16.2133 7.50254 17.4133 8.20674C17.0133 11.9364 18.1333 15.64 20.4267 18.535C19.0933 21.0388 16.4267 22.7602 13.3333 22.7602C11.2116 22.7602 9.17677 21.9359 7.67648 20.4685C6.17619 19.0011 5.33333 17.0109 5.33333 14.9358C5.33333 12.8606 6.17619 10.8704 7.67648 9.40305C9.17677 7.93568 11.2116 7.11132 13.3333 7.11132ZM50.6667 7.11132C52.7884 7.11132 54.8232 7.93568 56.3235 9.40305C57.8238 10.8704 58.6667 12.8606 58.6667 14.9358C58.6667 17.0109 57.8238 19.0011 56.3235 20.4685C54.8232 21.9359 52.7884 22.7602 50.6667 22.7602C47.5733 22.7602 44.9067 21.0388 43.5733 18.535C45.8667 15.64 46.9867 11.9364 46.5867 8.20674C47.7867 7.50254 49.1733 7.11132 50.6667 7.11132ZM14.6667 33.8449C14.6667 28.446 22.4267 24.0643 32 24.0643C41.5733 24.0643 49.3333 28.446 49.3333 33.8449V38.4091H14.6667V33.8449ZM0 38.4091V34.4969C0 30.8716 5.04 27.82 11.8667 26.9333C10.2933 28.7068 9.33333 31.1585 9.33333 33.8449V38.4091H0ZM64 38.4091H54.6667V33.8449C54.6667 31.1585 53.7067 28.7068 52.1333 26.9333C58.96 27.82 64 30.8716 64 34.4969V38.4091Z"
        fill="url(#paint0_linear_7891_25129)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7891_25129"
          x1="32"
          y1="0.590942"
          x2="32"
          y2="38.4091"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};
