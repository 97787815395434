import React from 'react';

const Broadcast = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="64" height="64" rx="32" fill="#D9E5FC" />
    <path
      d="M20 28.5358C20.5156 28.5358 20.9375 28.8639 21.2188 29.2858L41 23.2858V41.2858L34.8125 39.4576C34.2969 41.3795 32.5625 42.7858 30.5 42.7858C28.0156 42.7858 26 40.7701 26 38.2858C26 37.817 26.0938 37.3014 26.2344 36.8795L21.2188 35.3326C20.9375 35.7545 20.5156 36.0358 20 36.0358C19.1562 36.0358 18.5 35.3795 18.5 34.5358V30.0358C18.5 29.2389 19.1562 28.5358 20 28.5358ZM28.3906 37.4889C28.2969 37.7701 28.25 38.0045 28.25 38.2858C28.25 39.5514 29.2344 40.5358 30.5 40.5358C31.5312 40.5358 32.4219 39.7858 32.6562 38.8014L28.3906 37.4889ZM44.75 21.7858C45.125 21.7858 45.5 22.1608 45.5 22.5358V42.0358C45.5 42.4576 45.125 42.7858 44.75 42.7858H43.25C42.8281 42.7858 42.5 42.4576 42.5 42.0358V22.5358C42.5 22.1608 42.8281 21.7858 43.25 21.7858H44.75Z"
      fill="white"
    />
  </svg>
);

export default Broadcast;
