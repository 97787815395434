'use client';

import React, { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { Box, Card } from '@mantine/core';
import { Debt } from '@/types';
import { formatCurrency, getLatestDebtStats } from '@/utils';
import { usePreferencesStore } from '@/stores';

const ReactApexChart = dynamic(() => import('react-apexcharts'), {
  ssr: false,
});

class ChartErrorBoundary extends React.Component<
  { children: React.ReactNode; onError: () => void },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode; onError: () => void }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    console.error('ChartErrorBoundary caught an error:', error);
    this.props.onError();
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}

export function PlanBalanceChart({ debts }: { debts: Debt[] }) {
  const { colorScheme } = usePreferencesStore();
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    setShouldShow(false);
    requestAnimationFrame(() => {
      setShouldShow(!!debts.length);
    });
  }, [debts]);

  const totalBalance = useMemo(
    () =>
      debts.reduce((total, debt) => {
        return total + (getLatestDebtStats(debt).currentBalance || 0);
      }, 0),
    [debts]
  );

  const chartOptions: ApexCharts.ApexOptions = useMemo(() => {
    const options: ApexCharts.ApexOptions = {
      chart: {
        type: 'donut',
        background: 'transparent',
        foreColor: colorScheme === 'dark' ? '#C1C2C5' : '#000000',
      },
      plotOptions: {
        pie: {
          donut: {
            size: '65%',
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
                formatter: (val) => {
                  if (!isNaN(Number(val))) {
                    return formatCurrency(Number(val));
                  }
                  return val;
                },
                offsetY: 0,
              },
              total: {
                show: true,
                label: 'Total Balance',
                formatter: () => formatCurrency(totalBalance),
                fontWeight: 700,
              },
            },
          },
        },
      },
      labels: debts.map((debt) => debt.name ?? ''),
      legend: {
        position: 'bottom',
        markers: {
          offsetX: -5,
        },
        itemMargin: {
          horizontal: 15,
        },
      },
      dataLabels: {
        style: {
          fontSize: '12px',
        },
      },
      tooltip: {
        y: {
          formatter: (val: number) => formatCurrency(val),
        },
      },
    };
    return options;
  }, [colorScheme, debts, totalBalance]);

  const chartSeries = useMemo(
    () => debts.map((debt) => getLatestDebtStats(debt).currentBalance || 0),
    [debts]
  );

  const handleChartError = () => {
    setShouldShow(false);
    // Reset the chart after a short delay
    setTimeout(() => {
      setShouldShow(true);
    }, 100);
  };

  return (
    <Card p="lg" withBorder w="100%" radius="md" shadow="md">
      <Box mih={300}>
        {shouldShow && (
          <ChartErrorBoundary onError={handleChartError}>
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="donut"
              height={300}
            />
          </ChartErrorBoundary>
        )}
      </Box>
    </Card>
  );
}
