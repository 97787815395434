import React from 'react';

const Fire = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="16" cy="16.5" r="16" fill="url(#paint0_linear_1709_7888)" />
    <g clipPath="url(#clip0_1709_7888)">
      <path
        d="M21.8752 10.2903C21.3417 9.70788 20.5123 8.92173 20.1595 10.6968C20.0395 11.3004 19.7591 11.7656 19.5189 12.1402C19.2599 10.8296 18.4808 9.47308 17.7467 8.55843C17.472 8.21623 16.4973 6.85588 16.3101 4.58008C16.2821 4.24043 15.8787 4.08093 15.6263 4.31003C13.2147 6.49923 11.891 9.51013 11.8419 12.8998C11.8419 12.8998 10.8377 12.0533 10.2921 10.4786C10.1452 10.0546 9.57671 9.97368 9.33006 10.3486C9.28271 10.4206 9.23881 10.4927 9.19921 10.5627C7.33856 13.8527 6.44351 17.848 7.27096 21.565C8.65446 27.7898 17.8062 29.5301 22.6511 25.6177C27.3914 21.7899 25.8846 14.6654 21.8752 10.2903Z"
        fill="#ED694A"
      />
      <path
        d="M20.4125 14.8409C20.0185 14.4107 19.4058 13.83 19.1452 15.1411C19.0566 15.5869 18.8495 15.9306 18.6721 16.2073C18.4809 15.2392 17.9054 14.2372 17.3632 13.5617C17.1603 13.3089 16.4404 12.3042 16.302 10.6231C16.2814 10.3722 15.9834 10.2545 15.797 10.4237C14.0158 12.0406 13.038 14.2646 13.0018 16.7682C13.0018 16.7682 12.26 16.143 11.8571 14.9799C11.7486 14.6667 11.3287 14.607 11.1465 14.8839C11.1116 14.9371 11.0791 14.9904 11.0499 15.0421C9.67554 17.4721 9.01444 20.4231 9.62559 23.1686C10.6475 27.7664 17.4071 29.0518 20.9857 26.162C24.4869 23.3347 23.3739 18.0725 20.4125 14.8409Z"
        fill="#F4A32C"
      />
      <path
        d="M19.077 18.9949C18.8103 18.7037 18.3955 18.3106 18.2191 19.1982C18.1592 19.5 18.0189 19.7326 17.8988 19.9199C17.7693 19.2645 17.3798 18.5863 17.0127 18.129C16.8754 17.9579 16.3881 17.2777 16.2944 16.1398C16.2804 15.9699 16.0787 15.8902 15.9525 16.0048C14.7467 17.0994 14.0849 18.6049 14.0603 20.2996C14.0603 20.2996 13.5582 19.8764 13.2855 19.0891C13.212 18.8771 12.9278 18.8366 12.8045 19.0241C12.7808 19.06 12.7589 19.0961 12.739 19.1311C11.8087 20.7761 11.3612 22.7737 11.7749 24.6323C12.4666 27.7447 17.0425 28.6149 19.4649 26.6586C20.2945 25.9887 20.8088 25.0448 20.9131 24.0006C21.0706 22.4191 20.4619 20.5062 19.077 18.9949Z"
        fill="#F4D44E"
      />
      <path
        d="M17.838 22.85C17.6894 22.6878 17.4583 22.4688 17.36 22.9632C17.3266 23.1314 17.2485 23.261 17.1816 23.3653C17.1094 23.0002 16.8924 22.6223 16.6879 22.3676C16.6114 22.2722 16.3399 21.8933 16.2877 21.2593C16.2799 21.1647 16.1675 21.1203 16.0972 21.1841C15.4255 21.7939 15.0567 22.6327 15.043 23.5769C15.043 23.5769 14.7633 23.3411 14.6113 22.9024C14.5704 22.7843 14.412 22.7617 14.3434 22.8662C14.3302 22.8863 14.318 22.9064 14.307 22.9259C13.7887 23.8424 13.5393 24.9553 13.7698 25.9907C14.2947 28.3523 18.5971 28.2807 18.861 25.6388C18.9486 24.7577 18.6095 23.6919 17.838 22.85Z"
        fill="#EAE9E8"
      />
      <path
        d="M14.7688 26.4597C14.5383 25.5334 14.7876 24.5379 15.3059 23.7181C15.3169 23.7006 15.3292 23.6827 15.3423 23.6647C15.411 23.5713 15.5694 23.5914 15.6103 23.6971C15.7622 24.0895 16.042 24.3004 16.042 24.3004C16.0532 23.6064 16.3046 22.9764 16.7664 22.4711C16.6631 22.3281 16.3472 21.9833 16.2877 21.2597C16.2799 21.165 16.1675 21.1207 16.0972 21.1844C15.4255 21.7943 15.0567 22.633 15.043 23.5772C15.043 23.5772 14.7633 23.3414 14.6113 22.9028C14.5704 22.7847 14.412 22.7621 14.3434 22.8666C14.3302 22.8867 14.318 22.9068 14.307 22.9263C13.7887 23.8427 13.5393 24.9557 13.7698 25.9911C13.9831 26.9509 14.8594 27.5277 15.8282 27.6669C15.3056 27.4102 14.904 27.0038 14.7688 26.4597Z"
        fill="#F7E7A1"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1709_7888"
        x1="7.2"
        y1="7.7"
        x2="24.8"
        y2="29.3"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF09F" />
        <stop offset="1" stopColor="#FCCF5A" />
      </linearGradient>
      <clipPath id="clip0_1709_7888">
        <rect width="25.6" height="25.6" fill="white" transform="translate(3.2002 2.09998)" />
      </clipPath>
    </defs>
  </svg>
);

export default Fire;
