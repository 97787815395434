'use client';

import { useState } from 'react';
import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { Debt, DebtUpdate } from '@/types';
import { isDevMode } from '@/utils';
import { useFinex } from '@/hooks';
import { Modal, DebtUpdateForm } from '@/components';

interface EditDebtUpdateModalProps {
  debt: Debt;
  debtUpdate: DebtUpdate;
  isOpened: boolean;
  onClose: () => void;
}

export function EditDebtUpdateModal({
  debt,
  debtUpdate,
  isOpened,
  onClose,
}: EditDebtUpdateModalProps) {
  const { plans } = useFinex();
  const [error, setError] = useState<string | null>();

  const handleClose = () => {
    setError(null);
    onClose();
  };

  const handleSubmit = async (updatedDebtUpdate: Omit<DebtUpdate, 'id'>) => {
    console.log('Upating debt update...', { updatedDebtUpdate });
    if (debtUpdate?.id) {
      const res = await plans.updateDebtUpdate(debtUpdate.id, {
        ...updatedDebtUpdate,
      });
      if (res.error) {
        setError(res.error);
        return;
      }
    }
    handleClose();
  };

  const handleDelete = () => {
    modals.openConfirmModal({
      title: 'Delete debt billing period',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this billing period?
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: async () => {
        console.log('Delete debt update...', debtUpdate.id);
        handleClose();
        if (!debtUpdate?.id) return;
        const res = await plans.deleteDebtUpdate(debtUpdate.id);
        if (res.error) {
          setError(res.error);
          return;
        }
      },
    });
  };

  return (
    <Modal
      isOpened={isOpened}
      onClose={handleClose}
      title="Edit debt billing period"
      error={error}
    >
      <DebtUpdateForm
        debt={debt}
        initialValues={debtUpdate}
        onSubmit={handleSubmit}
        onCancel={handleClose}
        onDelete={isDevMode() ? handleDelete : undefined}
        submitLabel="Save"
      />
    </Modal>
  );
}
