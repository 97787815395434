'use client';

import { useEffect, useMemo, useState } from 'react';
import { usePathname } from 'next/navigation';
import { IconStarFilled } from '@tabler/icons-react';
import { Card, Text, Title, Stack, Group, Badge } from '@mantine/core';
import { format } from 'date-fns';
import { Plan } from '@/types';
import { getDebtFreeCountdown } from '@/utils';
import { useFinex, usePayoffStats, usePlanSchedule } from '@/hooks';
import { LoaderTransition } from './LoaderTransition';
import { InstantLink } from './InstantLink';

interface DebtFreeCountdownCardProps {
  visibleFrom?: string;
  hiddenFrom?: string;
  mb?: number | string;
  isMobile?: boolean;
  plan?: Plan;
}

export function DebtFreeCountdownCard({
  visibleFrom,
  hiddenFrom,
  mb,
  isMobile,
  plan: overridePlan,
}: DebtFreeCountdownCardProps) {
  const { profile, plans } = useFinex();
  const pathname = usePathname();
  const [isOnNonDefaultPlan, setIsOnNonDefaultPlan] = useState(false);
  const defaultPlan = plans.data?.find(
    (plan) => plan.id === profile.data?.defaultPlanId
  );
  const plan = overridePlan || defaultPlan;

  const planSchedule = usePlanSchedule(plan);
  const planPayoffStats = usePayoffStats(planSchedule);

  const countdown = useMemo(() => {
    if (!planPayoffStats.payoffDate) return null;
    return getDebtFreeCountdown(planPayoffStats.payoffDate.toISOString());
  }, [planPayoffStats.payoffDate]);

  const countdownDate = useMemo(() => {
    if (!planPayoffStats.payoffDate) return null;
    return format(planPayoffStats.payoffDate, 'MMMM d, yyyy');
  }, [planPayoffStats.payoffDate]);

  useEffect(() => {
    if (!profile.data) return;
    setIsOnNonDefaultPlan(
      !!defaultPlan &&
        pathname.startsWith(`/plans/`) &&
        !pathname.startsWith(`/plans/${defaultPlan.id}`)
    );
  }, [pathname, profile.data, defaultPlan]);

  return (
    <Card
      className="active:translate-y-[2px] active:shadow-sm active:opacity-80 transition-all active:duration-0 duration-100 ease-in-out cursor-pointer"
      withBorder
      shadow="md"
      radius="md"
      visibleFrom={visibleFrom}
      hiddenFrom={hiddenFrom}
      mb={mb}
      component={InstantLink}
      href={`/plans/${plan?.id}?tab=progress`}
    >
      <Stack gap={0} align="center" ta="center">
        <Text size={isMobile ? 'md' : 'sm'} tt="uppercase" fw={700}>
          Debt&#8209;Free Countdown
        </Text>
        <Stack mt={isMobile ? 6 : 4}>
          <LoaderTransition
            isLoading={!profile.data}
            showInitialLoading={!profile.data}
            height={isOnNonDefaultPlan ? undefined : isMobile ? 66 : 50}
          >
            <Stack ta="center" gap={5} mb={0}>
              {countdown !== null ? (
                <Title
                  fw={800}
                  c="blue"
                  lh={1}
                  order={isMobile ? 1 : 2}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {countdown.toLocaleString()} day{countdown === 1 ? '' : 's'}{' '}
                  left
                </Title>
              ) : (
                <Title fw={500} c="dimmed" size={isMobile ? 'sm' : 'xs'}>
                  Setup a valid plan to get your countdown.
                </Title>
              )}
              {profile.data?.debtFreeDate ? (
                <Text c="dimmed" size={isMobile ? 'md' : 'xs'} mt={2}>
                  {countdownDate}
                </Text>
              ) : null}
              {defaultPlan &&
              plan?.id === defaultPlan.id &&
              isOnNonDefaultPlan ? (
                <Group w="100%" justify="center" mt={2}>
                  <Badge
                    size="sm"
                    radius="xl"
                    variant="gradient"
                    tt="none"
                    h={18}
                    rightSection={<IconStarFilled size={10} />}
                    className="cursor-pointer"
                  >
                    {defaultPlan.name}
                  </Badge>
                </Group>
              ) : null}
            </Stack>
          </LoaderTransition>
        </Stack>
      </Stack>
    </Card>
  );
}
