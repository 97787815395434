import React from 'react';

function Expand(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M13.824 9.72711L10.6334 12.8479C10.3988 13.0544 10.0469 13.0544 9.83578 12.8249L9.31965 12.3201C9.08504 12.1136 9.08504 11.7464 9.31965 11.5399L10.7507 10.2549H2.56305C2.2346 10.2549 2 10.0025 2 9.70416V5.56003C2 5.25075 2.25072 5.00003 2.56 5.00003L3.31683 5.00003C3.62611 5.00004 3.87683 5.25076 3.87683 5.56003V8.41913H10.7507L9.31965 7.11116C9.08504 6.90463 9.08504 6.53748 9.31965 6.33096L9.83578 5.82613C10.0469 5.59666 10.3988 5.59666 10.6334 5.80318L13.824 8.92396C14.0587 9.15343 14.0587 9.49764 13.824 9.72711Z"
        fill="inherit"
      />
    </svg>
  );
}

export default Expand;
