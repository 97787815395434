'use client';

import { Stack, Group, Title } from '@mantine/core';
import { FinexLogo } from './FinexLogo';
import { InstantLink } from './InstantLink';

export function CenteredLogo() {
  return (
    <Stack gap={6} align="center" mb="xs">
      <InstantLink href="/" className="cursor-pointer">
        <Group gap={8}>
          <FinexLogo size={30} />
          <Title order={2} ta="center">
            Finex
          </Title>
        </Group>
      </InstantLink>
    </Stack>
  );
}
