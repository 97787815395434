import React from 'react';

const Brand = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M13.6435 20.7628C12.5251 21.8728 11.4652 21.8812 10.3552 20.7628L9.10328 19.5109C8.98644 19.394 8.88629 19.3607 8.73606 19.3607H6.9667C5.38931 19.3607 4.63817 18.6095 4.63817 17.0321V15.2628C4.63817 15.1125 4.59644 15.004 4.48794 14.8955L3.23604 13.6353C2.12602 12.5253 2.11767 11.4653 3.23604 10.3553L4.48794 9.10342C4.59644 8.99492 4.63817 8.88642 4.63817 8.73619V6.96684C4.63817 5.37275 5.38931 4.6383 6.9667 4.6383H8.73606C8.88629 4.6383 8.99478 4.59657 9.10328 4.48807L10.3552 3.24452C11.4652 2.11781 12.5251 2.11781 13.6435 3.23617L14.8954 4.48807C15.0039 4.59657 15.1124 4.6383 15.2626 4.6383H17.032C18.6094 4.6383 19.3605 5.38944 19.3605 6.96684V8.73619C19.3605 8.88642 19.4023 8.98657 19.5108 9.10342L20.7627 10.3553C21.8727 11.4653 21.881 12.5253 20.7627 13.6353L19.5108 14.8955C19.4023 15.004 19.3605 15.1125 19.3605 15.2628V17.0321C19.3605 18.6095 18.6094 19.3607 17.032 19.3607H15.2626C15.1124 19.3607 15.0123 19.394 14.8954 19.5109L13.6435 20.7628Z"
      fill="url(#paint0_linear_482_20867)"
    />
    <path
      d="M9.90432 15.9736C10.0879 16.1656 10.3216 16.2741 10.6137 16.2741C10.9225 16.2741 11.1729 16.1656 11.3649 15.9152L16.047 9.34691C16.1722 9.17164 16.2389 8.96299 16.2389 8.79607C16.2389 8.312 15.8717 7.95312 15.3543 7.95312C15.0204 7.95312 14.8034 8.06997 14.6031 8.35373L10.5803 14.0958L8.56896 11.8507C8.38535 11.6504 8.1767 11.5586 7.89293 11.5586C7.37548 11.5586 7.0166 11.9175 7.0166 12.4182C7.0166 12.6269 7.09172 12.8355 7.2336 12.9858L9.90432 15.9736Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_482_20867"
        x1="5.40039"
        y1="4.79844"
        x2="5.40039"
        y2="23.3984"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDB7D" />
        <stop offset="1" stopColor="#D49900" />
      </linearGradient>
    </defs>
  </svg>
);

export default Brand;
