'use client';

import {
  Badge,
  Card,
  Group,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { IconChevronCompactRight, IconEdit } from '@tabler/icons-react';
import {
  Debt,
  MinimumPaymentCalculationType,
  debtCategoryLabels,
} from '@/types';
import { formatCurrency } from '@/utils';
import { useLatestDebtStats } from '@/hooks';
import { InstantLink } from './InstantLink';

interface DebtCardProps {
  debt: Debt;
  showChevron?: boolean;
  showEditIcon?: boolean;
  onClick?: () => void;
}

export function DebtCard({
  debt,
  showChevron = true,
  showEditIcon = false,
  onClick,
}: DebtCardProps) {
  const debtStats = useLatestDebtStats(debt);

  return (
    <Card
      className="active:translate-y-[2px] active:shadow-sm active:opacity-80 transition-all active:duration-0 duration-100 ease-in-out cursor-pointer"
      withBorder
      shadow="md"
      radius="md"
      pr={showChevron ? 4 : undefined}
      component={showChevron ? InstantLink : undefined}
      href={`/plans/${debt.planId}/debts/${debt.id}`}
      onClick={onClick}
    >
      <Group align="center" justify="space-between" wrap="nowrap" gap="xs">
        <Stack gap="md" style={{ flex: 1 }}>
          <Group align="flex-start" justify="space-between" pr={4}>
            <Stack gap={4} flex={1}>
              <Title fw={500} lineClamp={2} order={4}>
                {debt.name}
              </Title>
              <Badge size="sm" variant="light">
                {debt.category ? debtCategoryLabels[debt.category] : ''}
              </Badge>
            </Stack>

            {showEditIcon && (
              <IconEdit
                style={{
                  color: 'var(--mantine-color-dimmed)',
                  cursor: 'pointer',
                }}
                size={24}
              />
            )}
          </Group>

          <SimpleGrid cols={{ base: 2, md: 4 }} spacing="sm">
            <Stack gap={2}>
              <Text size="xs" c="dimmed">
                Current balance
              </Text>
              <Text fw={500} size="sm">
                {formatCurrency(debtStats.currentBalance)}
              </Text>
            </Stack>

            <Stack gap={2}>
              <Text size="xs" c="dimmed">
                Starting balance
              </Text>
              <Text fw={500} size="sm">
                {formatCurrency(debtStats.initialBalance)}
              </Text>
            </Stack>

            <Stack gap={2}>
              <Text size="xs" c="dimmed">
                APR
              </Text>
              <Stack gap={0}>
                <Text fw={500} size="sm">
                  {debt.apr}%
                </Text>
                {debt.promoApr !== undefined && debt.promoApr !== null && (
                  <Text size="xs" c="green">
                    {debt.promoApr}% until{' '}
                    {debt.promoAprEndDate
                      ? new Date(debt.promoAprEndDate).toLocaleDateString()
                      : '--'}
                  </Text>
                )}
              </Stack>
            </Stack>

            <Stack gap={2}>
              <Text size="xs" c="dimmed">
                Minimum
              </Text>
              {debt.minimumPaymentCalculationType ===
              MinimumPaymentCalculationType.FixedAmount ? (
                <Text fw={500} size="sm">
                  {formatCurrency(debtStats.lastMinimumPayment)}
                </Text>
              ) : (
                <Stack gap={0}>
                  <Text fw={500}>
                    {debt.minimumPaymentPercent}% of{' '}
                    {debt.minimumPaymentCalculationType ===
                    MinimumPaymentCalculationType.PercentOfPrincipal
                      ? 'Principal'
                      : 'Principal & Interest'}
                  </Text>
                  {Boolean(debt.lowestPaymentAllowed) && (
                    <Text size="sm" c="dimmed">
                      Min: {formatCurrency(debt.lowestPaymentAllowed)}
                    </Text>
                  )}
                </Stack>
              )}
            </Stack>
          </SimpleGrid>
        </Stack>
        {showChevron && (
          <IconChevronCompactRight
            style={{ color: 'var(--mantine-color-gray-4)' }}
            size={30}
          />
        )}
      </Group>
    </Card>
  );
}
