'use client';

import { useMemo } from 'react';
import {
  Container,
  Text,
  Title,
  Stack,
  Group,
  Card,
  Button,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { format } from 'date-fns';
import { getTimesOfDayName } from '@/utils';
import { useDefaultPlan, useFinex } from '@/hooks';
import {
  CenteredLoader,
  PayoffTimelineChart,
  PlanBalanceChart,
  PlanStatsCards,
} from '@/components';
import { DebtFreeCountdownCard } from './DebtFreeCountdownCard';
import { InstantLink } from './InstantLink';
import { ReadyCountAlert } from './ReadyCountAlert';

export function HomeLoggedIn() {
  const { stripeState } = useFinex();
  const { plan, stats } = useDefaultPlan();
  const timeOfDay = useMemo(() => getTimesOfDayName(), []);
  const isMobile = useMediaQuery('(max-width: 48em)');

  if (!stripeState.isSubscriptionActive) {
    return <CenteredLoader />;
  }

  return (
    <Container p="lg">
      <Stack>
        <Stack gap={6} mb="xs">
          <Title order={2}>Good {timeOfDay}!</Title>
          <Text>{format(new Date(), 'EEEE, LLLL d, yyyy')}</Text>
          <Text c="dimmed" size="sm">
            Your debt&#8209;free life is ahead.
            <br />
            You got this. 💪
          </Text>
        </Stack>

        <ReadyCountAlert plan={plan} />

        {!plan?.debts.length ? (
          <Card withBorder shadow="md" py="lg">
            <Stack gap="sm" align="center">
              <Text c="dimmed" ta="center">
                Add debts to {plan ? 'your' : 'a'} plan to see charts here.
              </Text>
              <Button
                component={InstantLink}
                href={plan ? `/plans/${plan.id}?tab=debts` : '/plans'}
              >
                Go to plan{plan ? '' : 's'}
              </Button>
            </Stack>
          </Card>
        ) : (
          <>
            {isMobile && <DebtFreeCountdownCard isMobile={isMobile} />}

            <PlanStatsCards plan={plan} />

            <Group w="100%">
              <PayoffTimelineChart debtPayments={stats.debtPayments} />
            </Group>

            <Group w="100%">
              <PlanBalanceChart debts={plan?.debts ?? []} />
            </Group>
          </>
        )}
      </Stack>
    </Container>
  );
}
