'use client';

import { useMemo } from 'react';
import {
  Button,
  Card,
  Group,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { IconChevronCompactRight, IconEdit } from '@tabler/icons-react';
import { differenceInMonths, format } from 'date-fns';
import { DebtUpdate } from '@/types';
import { cn, formatCurrency } from '@/utils';

interface DebtUpdateCardProps {
  debtUpdate: DebtUpdate;
  isInitial?: boolean;
  showChevron?: boolean;
  showEditIcon?: boolean;
  isPlaceholder?: boolean;
  onClick?: () => void;
}

export function DebtUpdateCard({
  debtUpdate,
  isInitial,
  showChevron = true,
  showEditIcon = false,
  isPlaceholder,
  onClick,
}: DebtUpdateCardProps) {
  const balanceAfterPayment = useMemo(() => {
    if (!debtUpdate.balance || !debtUpdate.payment) return null;
    return debtUpdate.balance - debtUpdate.payment;
  }, [debtUpdate]);

  const isPaymentBelowMinimum = useMemo(() => {
    if (debtUpdate.minimumPayment === null || debtUpdate.payment === null) {
      return false;
    }
    return debtUpdate.minimumPayment > debtUpdate.payment;
  }, [debtUpdate.minimumPayment, debtUpdate.payment]);

  return (
    <Card
      className={cn(
        'transition-all duration-100 ease-in-out cursor-pointer active:duration-0 active:translate-y-[2px] active:shadow-sm active:opacity-80',
        {
          'border-blue-200': isPlaceholder,
        }
      )}
      withBorder
      shadow="md"
      radius="md"
      pr={showChevron ? 4 : undefined}
      onClick={onClick}
    >
      <Group align="center" justify="space-between" wrap="nowrap" gap="xs">
        <Stack gap="md" style={{ flex: 1 }}>
          <Group align="flex-start" justify="space-between" pr={4}>
            <Stack gap={4} flex={1}>
              <Title fw={500} lineClamp={2} order={4}>
                {debtUpdate.endDate &&
                  format(debtUpdate.endDate, 'MMMM d, yyyy')}
              </Title>
              {debtUpdate.startDate && debtUpdate.endDate && (
                <Text size="xs" c="gray.8">
                  Billing period: {format(debtUpdate.startDate, 'PP')} -{' '}
                  {format(debtUpdate.endDate, 'PP')}
                </Text>
              )}
            </Stack>

            {showEditIcon && (
              <IconEdit
                style={{
                  color: 'var(--mantine-color-dimmed)',
                  cursor: 'pointer',
                }}
                size={24}
              />
            )}
          </Group>

          {isPlaceholder && (
            <Group w="100%" justify="center" mb={4}>
              <Button className="cursor-pointer">Track billing period</Button>
            </Group>
          )}

          {isPaymentBelowMinimum && (
            <Text size="xs" c="yellow.9" my={-5}>
              Payment is below the minimum.
            </Text>
          )}

          {!isPlaceholder && (
            <SimpleGrid cols={{ base: 3, md: 5 }} spacing="md">
              <Stack gap={2}>
                <Text size="xs" c="dimmed">
                  Balance before payment
                </Text>
                <Text fw={500} size="sm">
                  {formatCurrency(debtUpdate.balance)}
                </Text>
              </Stack>

              <Stack gap={2}>
                <Text size="xs" c="dimmed">
                  Minimum payment
                </Text>
                <Text fw={500} size="sm">
                  {formatCurrency(debtUpdate.minimumPayment)}
                </Text>
              </Stack>

              <Stack gap={2}>
                <Text size="xs" c="dimmed">
                  Interest charged
                </Text>
                <Stack gap={0}>
                  <Text fw={500} size="sm">
                    {formatCurrency(debtUpdate.interestCharged)}
                  </Text>
                </Stack>
              </Stack>

              <Stack gap={2}>
                <Text
                  size="xs"
                  c={
                    isPaymentBelowMinimum
                      ? 'yellow.9'
                      : debtUpdate.payment !== null
                        ? 'green.8'
                        : 'dimmed'
                  }
                >
                  Your actual payment
                </Text>
                <Text
                  fw={800}
                  size="sm"
                  c={
                    isPaymentBelowMinimum
                      ? 'yellow.9'
                      : debtUpdate.payment !== null
                        ? 'green.9'
                        : 'dimmed'
                  }
                >
                  {debtUpdate.payment !== null
                    ? formatCurrency(debtUpdate.payment)
                    : '--'}
                </Text>
              </Stack>

              <Stack gap={2}>
                <Text
                  size="xs"
                  c={
                    isPaymentBelowMinimum
                      ? 'yellow.9'
                      : debtUpdate.payment !== null
                        ? 'green.8'
                        : 'dimmed'
                  }
                >
                  Balance after payment
                </Text>
                <Text
                  fw={800}
                  size="sm"
                  c={
                    isPaymentBelowMinimum
                      ? 'yellow.9'
                      : balanceAfterPayment !== null
                        ? 'green.9'
                        : 'dimmed'
                  }
                >
                  {balanceAfterPayment !== null
                    ? formatCurrency(balanceAfterPayment)
                    : '--'}
                </Text>
              </Stack>
            </SimpleGrid>
          )}
        </Stack>
        {showChevron && (
          <IconChevronCompactRight
            style={{ color: 'var(--mantine-color-gray-4)' }}
            size={30}
          />
        )}
      </Group>
    </Card>
  );
}
