'use client';

import { Stack, Text, Table, Card, ScrollArea, Box } from '@mantine/core';
import {
  Plan,
  PlanSchedule,
  PlanScheduleItemType,
  planScheduleItemTypeLabels,
} from '@/types';
import { formatCurrency } from '@/utils';
import { InstantLink } from './InstantLink';

export function PlanPaymentSchedule({
  plan,
  planSchedule,
}: {
  plan: Plan;
  planSchedule: PlanSchedule;
}) {
  return (
    <Card p="md" withBorder shadow="md" radius="md">
      <Stack>
        {plan.debts.length === 0 ? (
          <Text c="dimmed" ta="center">
            Add debts to see your payment steps.
          </Text>
        ) : (
          <ScrollArea>
            <Table striped highlightOnHover stickyHeader stickyHeaderOffset={0}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Date</Table.Th>
                  <Table.Th>Type</Table.Th>
                  <Table.Th>Account</Table.Th>
                  <Table.Th>Balance Before</Table.Th>
                  <Table.Th>Payment</Table.Th>
                  <Table.Th>Minimum</Table.Th>
                  <Table.Th>Extra</Table.Th>
                  <Table.Th>APR</Table.Th>
                  <Table.Th>Principal</Table.Th>
                  <Table.Th>Interest</Table.Th>
                  <Table.Th>Balance After</Table.Th>
                  <Table.Th>Payment Budget Before</Table.Th>
                  <Table.Th>Payment Budget After</Table.Th>
                  <Table.Th>Total Debt Before</Table.Th>
                  <Table.Th>Total Debt After</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {planSchedule?.map((step, index) => {
                  const prevStep = planSchedule[index - 1];
                  const isNewMonth =
                    index === 0 ||
                    step.date.getMonth() !== prevStep?.date.getMonth() ||
                    step.date.getFullYear() !== prevStep?.date.getFullYear();

                  return (
                    <Table.Tr
                      key={`${step.debtId}-${index}`}
                      style={{
                        borderTop:
                          isNewMonth && index !== 0 ? '3px solid #bbb' : '',
                        whiteSpace: 'nowrap',
                        background:
                          step.type === PlanScheduleItemType.DebtPayoff
                            ? '#baf1c2'
                            : undefined,
                        color:
                          step.type === PlanScheduleItemType.DebtPayoff
                            ? '#004e00'
                            : undefined,
                      }}
                    >
                      <Table.Td>{step.date.toLocaleDateString()}</Table.Td>
                      <Table.Td>
                        {step.debtUpdateId ? (
                          <InstantLink
                            href={`/plans/${plan.id}/debts/${step.debtId}?tab=history`}
                            className="text-blue-800 underline hover:text-blue-800"
                          >
                            {planScheduleItemTypeLabels[step.type]}
                          </InstantLink>
                        ) : (
                          planScheduleItemTypeLabels[step.type]
                        )}
                      </Table.Td>
                      <Table.Td>{step.debtName}</Table.Td>
                      <Table.Td>{formatCurrency(step.balanceBefore)}</Table.Td>
                      <Table.Td>{formatCurrency(step.totalPayment)}</Table.Td>
                      <Table.Td>{formatCurrency(step.minimumPayment)}</Table.Td>
                      <Table.Td>{formatCurrency(step.extraPayment)}</Table.Td>
                      <Table.Td>
                        {step.apr !== null && step.apr.toFixed(2) + '%'}
                      </Table.Td>
                      <Table.Td>{formatCurrency(step.principal)}</Table.Td>
                      <Table.Td>{formatCurrency(step.interest)}</Table.Td>
                      <Table.Td>{formatCurrency(step.balanceAfter)}</Table.Td>
                      <Table.Td>
                        {formatCurrency(step.debtPaymentBudgetBefore)}
                      </Table.Td>
                      <Table.Td>
                        {formatCurrency(step.debtPaymentBudgetAfter)}
                      </Table.Td>
                      <Table.Td>
                        {formatCurrency(step.totalDebtBefore)}
                      </Table.Td>
                      <Table.Td>{formatCurrency(step.totalDebtAfter)}</Table.Td>
                    </Table.Tr>
                  );
                })}
              </Table.Tbody>
            </Table>
          </ScrollArea>
        )}
      </Stack>
    </Card>
  );
}
