'use client';

import { Box, Grid, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Carousel } from '@mantine/carousel';
import { StatsCard, StatsCardProps } from './StatsCard';
import { CenteredLoader } from './CenteredLoader';

interface StatsCardsProps {
  items: StatsCardProps[];
}

export function StatsCards({ items }: StatsCardsProps) {
  const isMobile = useMediaQuery('(max-width: 48em)');

  if (!items.length) {
    return <CenteredLoader />;
  }

  return (
    <Stack w="100%">
      {isMobile ? (
        <Box ml="-20px" w="calc(100% + 40px)" pos="relative" pb={18}>
          <Carousel
            slideSize="70%"
            align="start"
            withControls={false}
            withIndicators
            containScroll="trimSnaps"
            styles={{
              indicators: {
                bottom: '20px',
                marginTop: '10px',
              },
              indicator: {
                backgroundColor: 'var(--mantine-color-blue-6)',
                width: '20px',
                height: '4px',
                marginLeft: 0,
                marginRight: 0,
                transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
              },
            }}
            mb={-40}
          >
            {items.map((cardProps, i) => (
              <Carousel.Slide key={cardProps.id} mb={40}>
                <StatsCard
                  {...cardProps}
                  ml="20px"
                  mr={i === items.length - 1 ? '20px' : undefined}
                />
              </Carousel.Slide>
            ))}
          </Carousel>
        </Box>
      ) : (
        <Grid grow w="100%" gutter="lg">
          {items.map((cardProps) => (
            <Grid.Col key={cardProps.id} span={{ base: 12, sm: 4 }}>
              <StatsCard {...cardProps} />
            </Grid.Col>
          ))}
        </Grid>
      )}
    </Stack>
  );
}
