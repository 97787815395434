import React from 'react';

export const Pin = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" fill="none" viewBox="0 0 35 34">
      <path
        fill="currentColor"
        d="M21.998 18.295c.372 2.014-.15 4.027-1.567 5.444a.833.833 0 01-1.193 0l-3.58-3.58-2.585 2.586c-.025.025-.074.025-.1.05l-1.59.795c-.224.125-.497-.149-.373-.373l.795-1.59.075-.075 2.585-2.585-3.58-3.58c-.348-.348-.348-.845 0-1.194 1.418-1.416 3.406-1.963 5.42-1.59l1.218-.92-.671-.671c-.348-.348-.348-.846 0-1.194l2.386-2.386a.833.833 0 011.194 0l6.761 6.761a.856.856 0 010 1.194l-2.386 2.386a.833.833 0 01-1.194 0l-.696-.696-.92 1.218zm-9.273-3.455l7.035 7.035c.746-1.143.845-2.635.398-3.928l2.61-3.405 1.442 1.441 1.193-1.193-5.568-5.568-1.193 1.193 1.442 1.442-3.406 2.61c-1.318-.472-2.81-.373-3.953.373z"
      ></path>
    </svg>
  );
};
