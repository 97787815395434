import React from 'react';

const AngleRight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 6 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.13281 4.71094L1.71094 8.17969C1.59375 8.29688 1.40625 8.29688 1.3125 8.17969L0.84375 7.71094C0.726562 7.59375 0.726562 7.42969 0.84375 7.3125L3.60938 4.5L0.84375 1.71094C0.726562 1.59375 0.726562 1.40625 0.84375 1.3125L1.3125 0.84375C1.40625 0.726562 1.59375 0.726562 1.71094 0.84375L5.13281 4.3125C5.25 4.42969 5.25 4.59375 5.13281 4.71094Z" />
  </svg>
);

export default AngleRight;
