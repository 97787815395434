import Gleap from 'gleap';
import { env } from '../env';
import { isDevMode } from '../isDevMode';
import './styles.css';

export function initializeGleapClient() {
  console.log('Initializing Gleap...');
  Gleap.initialize(env.NEXT_PUBLIC_GLEAP_SDK_KEY);
  Gleap.setEnvironment(isDevMode() ? 'dev' : 'prod');
  console.log('Gleap initialized.');
}
