'use client';

import { useMemo, useState } from 'react';
import { Debt, DebtUpdate } from '@/types';
import { useFinex } from '@/hooks';
import { getNextDebtUpdate } from '@/utils';
import { DebtUpdateForm, Modal } from '@/components';

interface AddDebtUpdateModalProps {
  debt: Debt;
  latestDebtUpdate?: DebtUpdate;
  isOpened: boolean;
  onClose: () => void;
}

export function AddDebtUpdateModal({
  debt,
  latestDebtUpdate,
  isOpened,
  onClose,
}: AddDebtUpdateModalProps) {
  const { plans } = useFinex();
  const [error, setError] = useState<string | null>();

  const handleClose = () => {
    setError(null);
    onClose();
  };

  const handleSubmit = async (values: Omit<DebtUpdate, 'id'>) => {
    setError(null);
    const res = await plans.createDebtUpdate({
      ...values,
      debtId: debt.id,
    });
    if (res.error) {
      setError(res.error);
      return;
    }
    handleClose();
  };

  const nextDebtUpdate = useMemo(
    () => getNextDebtUpdate(debt, latestDebtUpdate),
    [latestDebtUpdate, debt.apr]
  );

  return (
    <Modal
      isOpened={isOpened}
      onClose={handleClose}
      title="Add debt billing period"
      error={error}
    >
      <DebtUpdateForm
        debt={debt}
        initialValues={nextDebtUpdate}
        onSubmit={handleSubmit}
        onCancel={onClose}
        submitLabel="Add"
        isLoading={plans.isLoading}
      />
    </Modal>
  );
}
