'use client';

import { Alert, Modal as MantineModel, Stack, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

interface ModalProps {
  isOpened: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  error?: string | null;
  children: React.ReactNode;
}

export function Modal({
  isOpened,
  onClose,
  title,
  error,
  children,
}: ModalProps) {
  return (
    <MantineModel
      opened={isOpened}
      onClose={onClose}
      size="lg"
      radius="md"
      zIndex={1000}
      title={
        <Stack gap="xs" w="100%">
          {title && <Text fw={500}>{title}</Text>}
          {error && (
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              title="Error"
              color="red"
              variant="light"
              w="100%"
            >
              {error}
            </Alert>
          )}
        </Stack>
      }
      centered
      styles={{
        body: {
          backgroundColor: 'var(--mantine-color-gray-0)',
        },
        title: {
          width: '100%',
        },
        header: {
          alignItems: 'flex-start',
          marginBottom: error ? 0 : undefined,
          backgroundColor: 'var(--mantine-color-gray-0)',
        },
      }}
      overlayProps={{
        blur: 2,
      }}
    >
      {children}
    </MantineModel>
  );
}
