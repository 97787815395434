'use client';

import { useRouter } from 'next/navigation';
import {
  Badge,
  Button,
  Card,
  Divider,
  Group,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import {
  IconCircle,
  IconCircleDashed,
  IconCircleCheckFilled,
  IconChevronDown,
} from '@tabler/icons-react';
import { format } from 'date-fns';
import {
  Plan,
  PlanScheduleItem,
  DebtPaymentStatus,
  DebtPayoffMethod,
} from '@/types';
import { formatCurrency } from '@/utils';
import { MarkAsCompleteModal } from './MarkAsCompleteModal';
import { InstantLink } from './InstantLink';

const GREEN = '#2e9e44';

export function PlanStepDebtPaymentCard({
  plan,
  item,
  stepCount,
  totalStepsCount,
  setExpanded,
  isExpanded,
}: {
  plan: Plan;
  item: PlanScheduleItem;
  stepCount: number;
  totalStepsCount: number;
  setExpanded: (value: boolean) => void;
  isExpanded: boolean;
}) {
  const router = useRouter();
  const [markModalOpened, { open: openMarkModal, close: closeMarkModal }] =
    useDisclosure(false);

  const isTooEarly = item.debtPaymentStatus === DebtPaymentStatus.TooEarly;
  const isReady = item.debtPaymentStatus === DebtPaymentStatus.Ready;
  const isCompleted = item.debtPaymentStatus === DebtPaymentStatus.Paid;
  const isBelowMinimum =
    item.debtPaymentStatus === DebtPaymentStatus.PaidBelowMinimum;

  const openMarkAsCompleteModal = () => {
    if (item.debtPaymentStatus !== DebtPaymentStatus.Ready) return;
    openMarkModal();
  };

  const onPriorityDebtBadgeClick = () => {
    modals.openConfirmModal({
      centered: true,
      title: <Text fw={500}>What is a priority debt?</Text>,
      children: (
        <Stack mb="xl">
          <Text size="sm" lh={1.6}>
            Your <b>priority debt</b> is the debt that is planned to be paid off
            next. All extra payments go to this debt while your other debts only
            get their minimum payments.
          </Text>
          <Text size="sm" lh={1.6}>
            Your current priority debt is{' '}
            <Text component="span" c="blue.7" fw={500}>
              {item.debtName}
            </Text>{' '}
            since your plan is using the{' '}
            <Text component="span" c="blue.7" fw={500} className="capitalize">
              {plan.debtPayoffMethod}
            </Text>{' '}
            method, which pays off{' '}
            {plan.debtPayoffMethod === DebtPayoffMethod.Avalanche && (
              <Text component="span">
                the highest interest first. This method saves you the most money
                in interest over time.
              </Text>
            )}
            {plan.debtPayoffMethod === DebtPayoffMethod.Snowball && (
              <Text component="span">
                the smallest balance first. This method helps you build momentum
                and motivation through quick wins.
              </Text>
            )}
            {plan.debtPayoffMethod === DebtPayoffMethod.Custom && (
              <Text component="span">the debts in the order you specify.</Text>
            )}
          </Text>
          <Text size="sm" lh={1.6}>
            You can adjust this anytime in your plan settings.
          </Text>
        </Stack>
      ),
      labels: { cancel: 'Close', confirm: 'Go to settings' },
      cancelProps: { variant: 'light' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => {
        router.push(`/plans/${plan.id}?tab=settings`);
      },
    });
  };

  return (
    <>
      <Card
        className="transition-all duration-100 ease-in-out"
        shadow="md"
        radius="md"
        withBorder
        pos="relative"
        p={0}
        style={{
          borderColor: isBelowMinimum
            ? '#fdba74'
            : isCompleted
              ? '#16a34a'
              : '',
        }}
      >
        <Group
          gap="xs"
          justify="space-between"
          className="cursor-pointer"
          onClick={() => setExpanded(!isExpanded)}
          p="md"
          py="sm"
          wrap="nowrap"
          w="100%"
        >
          <Stack>
            {isTooEarly && <IconCircle size={36} color="grey" opacity={0.4} />}
            {isReady && (
              <IconCircleDashed
                size={36}
                color={GREEN}
                onClick={openMarkAsCompleteModal}
                className="animate-[spin_15s_linear_infinite]"
              />
            )}
            {(isCompleted || isBelowMinimum) && (
              <IconCircleCheckFilled
                size={36}
                color={isBelowMinimum ? 'orange' : GREEN}
                opacity={isBelowMinimum ? 0.8 : 1}
                onClick={openMarkAsCompleteModal}
              />
            )}
          </Stack>
          <Stack gap={0} flex={1}>
            <Text
              size="sm"
              fw={500}
              c={
                isBelowMinimum ? '#d38b0a' : isCompleted ? '#186b29' : 'gray.7'
              }
            >
              {format(item.date, 'MMMM d, yyyy')}
            </Text>
            <Text size="xs" c="dimmed">
              {item.debtName} • {formatCurrency(item.totalPayment)}
            </Text>
          </Stack>
          <Group>
            <IconChevronDown
              size={20}
              style={{
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
              color={isBelowMinimum ? 'orange' : isCompleted ? GREEN : 'gray'}
            />
          </Group>
        </Group>

        {isExpanded && (
          <Group p="md" pt={0} pb="lg" w="100%">
            <Divider color="gray.2" w="100%" />

            <Stack w="100%">
              <Stack gap="xs">
                <Group gap="xs" align="end">
                  <Text size="xs" c="dimmed">
                    Payment #{stepCount} of {totalStepsCount}
                  </Text>
                  {item.isPriorityDebt && (
                    <Badge
                      onClick={onPriorityDebtBadgeClick}
                      className="shadow-lg cursor-pointer active:translate-y-px"
                    >
                      Priority Debt
                    </Badge>
                  )}
                </Group>

                {isBelowMinimum && (
                  <Text size="xs" c="yellow.9">
                    Payment is below the minimum.
                  </Text>
                )}
              </Stack>

              <SimpleGrid cols={{ base: 2, sm: 3, md: 5 }} spacing="md">
                <Stack gap={0}>
                  <Text size="xs" c="dimmed">
                    Debt
                  </Text>
                  <Text
                    size="sm"
                    c="blue.7"
                    component={InstantLink}
                    href={`/plans/${plan.id}/debts/${item.debtId}`}
                  >
                    {item.debtName}
                  </Text>
                </Stack>

                <Stack gap={0}>
                  <Text size="xs" c="dimmed">
                    Due date
                  </Text>
                  <Text size="sm">{format(item.date, 'MMM d, yyyy')}</Text>
                </Stack>

                <Stack gap={0}>
                  <Text size="xs" c="dimmed">
                    Balance
                  </Text>
                  <Text size="sm">{formatCurrency(item.balanceBefore)}</Text>
                </Stack>

                <Stack gap={0}>
                  <Text size="xs" c="dimmed">
                    Payment
                  </Text>
                  <Text size="sm" c={isBelowMinimum ? 'yellow.9' : undefined}>
                    {formatCurrency(item.totalPayment)}
                  </Text>
                </Stack>

                <Stack gap={0}>
                  <Text size="xs" c="dimmed">
                    Minimum payment
                  </Text>
                  <Text size="sm">{formatCurrency(item.minimumPayment)}</Text>
                </Stack>

                <Stack gap={0}>
                  <Text size="xs" c="dimmed">
                    Extra payment
                  </Text>
                  <Text size="sm">{formatCurrency(item.extraPayment)}</Text>
                </Stack>

                <Stack gap={0}>
                  <Text size="xs" c="dimmed">
                    Interest charged
                  </Text>
                  <Group gap={6} align="end" mt={3}>
                    <Text size="sm" style={{ lineHeight: 1 }}>
                      {formatCurrency(item.interest)}
                    </Text>
                    <Text size="xs" c="dimmed" style={{ lineHeight: 1 }}>
                      {item.apr}% APR
                    </Text>
                  </Group>
                </Stack>

                <Stack gap={0}>
                  <Text size="xs" c="dimmed">
                    Balance after
                  </Text>
                  <Text size="sm">{formatCurrency(item.balanceAfter)}</Text>
                </Stack>

                <Stack gap={0}>
                  <Text size="xs" c="dimmed">
                    Total debt before
                  </Text>
                  <Text size="sm">{formatCurrency(item.totalDebtBefore)}</Text>
                </Stack>

                <Stack gap={0}>
                  <Text size="xs" c="dimmed">
                    Total debt after
                  </Text>
                  <Text size="sm">{formatCurrency(item.totalDebtAfter)}</Text>
                </Stack>
              </SimpleGrid>

              {isTooEarly && (
                <Text size="xs" c="dimmed" mt={6} ta="center">
                  The above values are estimated. You'll be able to update this
                  when it's closer to this billing period.
                </Text>
              )}

              {isReady && (
                <Stack justify="center" ta="center" gap="xs" mt="xs">
                  <Text size="xs" c="dimmed">
                    When you've made this payment, mark this as complete with
                    your actual amount.
                  </Text>
                  <Button color="green.8" onClick={openMarkAsCompleteModal}>
                    Mark as complete 🎉
                  </Button>
                </Stack>
              )}

              {isCompleted || isBelowMinimum ? (
                <Group
                  justify="center"
                  ta="center"
                  gap="xs"
                  mt={isCompleted ? 6 : 0}
                >
                  <Text
                    size="sm"
                    c="blue.7"
                    component={InstantLink}
                    href={`/plans/${plan.id}/debts/${item.debtId}?tab=history&editDebtUpdate=${item.debtUpdateId}`}
                  >
                    Edit details
                  </Text>
                </Group>
              ) : null}
            </Stack>
          </Group>
        )}
      </Card>

      <MarkAsCompleteModal
        plan={plan}
        item={item}
        isOpened={markModalOpened}
        onClose={closeMarkModal}
      />
    </>
  );
}
