'use client';

import { useMemo } from 'react';
import { Card, Group, Stack, Text } from '@mantine/core';
import { format } from 'date-fns';
import { Plan, PlanScheduleItem } from '@/types';
import { cn } from '@/utils';
import { InstantLink } from './InstantLink';

export function PlanStepDebtPayoffCard({
  plan,
  item,
}: {
  plan: Plan;
  item: PlanScheduleItem;
}) {
  const dateHasPassed = useMemo(() => {
    return new Date() >= item.date;
  }, [item.date]);

  return (
    <Card
      shadow="md"
      radius="md"
      withBorder
      className={cn('relative transition-all duration-100 ease-in-out', {
        'border-green-500': dateHasPassed,
      })}
      p={0}
    >
      <Group gap="xs" justify="space-between" p="md" py="sm">
        <Stack gap={0} flex={1} align="center" ta="center">
          <Text
            size="sm"
            fw={500}
            className={cn('text-gray-700', {
              'text-green-900': dateHasPassed,
            })}
          >
            <InstantLink href={`/plans/${plan.id}/debts/${item.debtId}`}>
              {item.debtName}
            </InstantLink>{' '}
            is&nbsp;paid&nbsp;off&nbsp;🎉
          </Text>
          <Text size="xs" c="dimmed">
            {format(item.date, 'MMMM d, yyyy')}
          </Text>
        </Stack>
      </Group>
    </Card>
  );
}
