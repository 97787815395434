'use client';

import { Text, Stack, Group, Button, Alert } from '@mantine/core';
import { Plan } from '@/types';
import { usePlanReadyCount } from '@/hooks';
import { InstantLink } from './InstantLink';

export function ReadyCountAlert({
  plan,
  isInProgressTab,
}: {
  plan?: Plan | null;
  isInProgressTab?: boolean;
}) {
  const readyCount = usePlanReadyCount(plan);
  if (!readyCount) return null;
  return (
    <Alert py="xs" px="md">
      <Group gap="md" align="center">
        <Stack flex={1}>
          <Text size="sm" c="blue.9" lh={1.4}>
            {readyCount > 0 && (
              <>
                You have {readyCount.toLocaleString()} payment{' '}
                {readyCount > 1 ? 's' : ''} that can be marked as complete
                {isInProgressTab ? <> below</> : null}.
              </>
            )}
          </Text>
        </Stack>
        {!isInProgressTab ? (
          <Button
            size="xs"
            component={InstantLink}
            href={plan ? `/plans/${plan.id}?tab=progress` : '/plans'}
          >
            Go to plan{plan ? '' : 's'}
          </Button>
        ) : null}
      </Group>
    </Alert>
  );
}
