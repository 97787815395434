'use client';

import { useState } from 'react';
import { Plan, PlannedExtraDebtPayment } from '@/types';
import { useFinex } from '@/hooks';
import { Modal, PlannedExtraDebtPaymentForm } from '@/components';

interface AddPlannedExtraDebtPaymentModalProps {
  plan: Plan;
  isOpened: boolean;
  onClose: () => void;
}

export function AddPlannedExtraDebtPaymentModal({
  plan,
  isOpened,
  onClose,
}: AddPlannedExtraDebtPaymentModalProps) {
  const { plans } = useFinex();
  const [error, setError] = useState<string | null>();

  const handleClose = () => {
    setError(null);
    onClose();
  };

  const handleSubmit = async (values: Omit<PlannedExtraDebtPayment, 'id'>) => {
    setError(null);
    const res = await plans.createPlannedExtraDebtPayment({
      ...values,
      planId: plan.id,
    });
    if (res.error) {
      setError(res.error);
      return;
    }
    handleClose();
  };

  return (
    <Modal
      isOpened={isOpened}
      onClose={handleClose}
      title="Add planned extra debt payment"
      error={error}
    >
      <PlannedExtraDebtPaymentForm
        onSubmit={handleSubmit}
        onCancel={onClose}
        submitLabel="Add"
        isLoading={plans.isLoading}
      />
    </Modal>
  );
}
