import { cn } from '@/utils';
import { Box, Group } from '@mantine/core';

interface DrawnSeparatorProps {
  className?: string;
}

export function DrawnSeparator({ className }: DrawnSeparatorProps) {
  return (
    <Group align="center" justify="center">
      <Box
        className={cn('relative w-[80%] max-w-[30rem]', className)}
        style={{
          aspectRatio: 1024 / 51,
        }}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 1024 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_689_989)">
            <rect width="1024" height="51" fill="white" />
            <path
              d="M382.537 11.9293C372.111 13.1349 363.278 13.917 354.523 15.2163C311.781 21.5538 269.085 28.2457 226.322 34.4041C180.733 40.9738 134.98 43.0347 89.0097 39.3324C66.4049 37.5118 43.7527 36.3672 21.4512 31.7363C16.1717 30.6366 10.9299 29.211 5.7891 27.5452C3.77045 26.8894 2.06604 25.1625 0 23.7776C3.31832 18.9349 7.08178 20.4582 10.3018 20.7636C19.4326 21.623 28.5124 23.1178 37.6462 23.9243C77.4692 27.4352 117.387 30.384 157.318 28.1154C186.921 26.4373 216.49 23.1259 245.916 19.2444C288.74 13.5952 331.416 6.73225 374.17 0.533176C378.404 -0.0777712 382.855 -0.224414 387.066 0.415044C394.419 1.52697 397.596 7.24131 395.246 14.6623C393.63 19.7454 391.202 24.5434 389.513 29.602C387.439 35.8255 388.942 38.306 395.25 37.862C401.663 37.4059 408.107 36.1921 414.364 34.571C437.662 28.5267 460.742 21.4438 484.195 16.1856C501.018 12.414 518.272 10.5974 535.402 8.55281C546.025 7.28612 555.134 10.9315 561.104 20.9877C563.178 24.4904 566.371 25.8671 570.092 25.5535C577.336 24.9466 584.685 24.7226 591.774 23.2074C647.097 11.3917 702.988 7.90526 759.298 9.92545C805.964 11.5954 852.621 13.697 899.302 14.7234C935.189 15.5176 971.103 14.9108 1007.01 14.9841C1011.05 14.9922 1015.18 14.9311 1019.1 15.7742C1021.04 16.1938 1023.95 18.5357 1024 20.0957C1024.06 21.9448 1021.86 24.7022 1020 25.582C1017.42 26.7998 1014.25 27.0035 1011.34 27.0076C968.423 27.089 925.494 27.6347 882.592 26.922C830.776 26.0585 778.984 23.6596 727.164 22.7513C681.978 21.9571 637.138 26.1074 592.815 35.8663C585.213 37.5403 577.336 38.086 569.552 38.5381C561.749 38.9862 555.231 35.7685 551.005 28.6449C547.59 22.8938 542.685 21.1954 536.555 21.3135C518.633 21.6597 501.084 24.9263 483.772 29.2681C460.952 34.9947 438.384 41.7884 415.611 47.7227C408.596 49.5515 401.278 50.9078 394.081 50.9974C379.056 51.1848 372.453 41.1774 377.106 26.2499C378.419 22.0059 380.229 17.9451 382.537 11.9293Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_689_989">
              <rect width="1024" height="51" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Box>
    </Group>
  );
}
