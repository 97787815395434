import React from 'react';

const InfoCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 2.25006C4.25 2.25006 1.25 5.28131 1.25 9.00006C1.25 12.7501 4.25 15.7501 8 15.7501C11.7188 15.7501 14.75 12.7501 14.75 9.00006C14.75 5.28131 11.6875 2.25006 8 2.25006ZM8 1.25006C12.25 1.25006 15.75 4.75006 15.75 9.00006C15.75 13.2813 12.25 16.7501 8 16.7501C3.71875 16.7501 0.25 13.2813 0.25 9.00006C0.25 4.75006 3.71875 1.25006 8 1.25006ZM6.875 12.0001H7.25V8.25006H6.875C6.65625 8.25006 6.5 8.09381 6.5 7.87506V7.62506C6.5 7.43756 6.65625 7.25006 6.875 7.25006H8.375C8.5625 7.25006 8.75 7.43756 8.75 7.62506V12.0001H9.125C9.3125 12.0001 9.5 12.1876 9.5 12.3751V12.6251C9.5 12.8438 9.3125 13.0001 9.125 13.0001H6.875C6.65625 13.0001 6.5 12.8438 6.5 12.6251V12.3751C6.5 12.1876 6.65625 12.0001 6.875 12.0001ZM8 4.50006C8.53125 4.50006 9 4.96881 9 5.50006C9 6.06256 8.53125 6.50006 8 6.50006C7.4375 6.50006 7 6.06256 7 5.50006C7 4.96881 7.4375 4.50006 8 4.50006Z"
      fill="#A5A9B5"
    />
  </svg>
);

export default InfoCircle;
