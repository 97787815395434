import React from 'react';

const EmptyPost = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="61"
    height="41"
    viewBox="0 0 61 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M58.3438 0.5C59.7148 0.5 60.875 1.66016 60.875 3.03125V38.4688C60.875 39.9453 59.7148 41 58.3438 41H5.1875C2.33984 41 0.125 38.7852 0.125 35.9375V6.40625C0.125 5.03516 1.17969 3.875 2.65625 3.875H6.875V3.03125C6.875 1.66016 7.92969 0.5 9.40625 0.5H58.3438ZM3.5 35.9375C3.5 36.8867 4.23828 37.625 5.1875 37.625C6.03125 37.625 6.875 36.8867 6.875 35.9375V7.25H3.5V35.9375ZM57.5 37.625V3.875H10.25V35.9375V36.043C10.25 36.4648 10.0391 37.2031 9.93359 37.625H57.5ZM14.8906 27.5C14.1523 27.5 13.625 26.9727 13.625 26.2344V8.51562C13.625 7.88281 14.1523 7.25 14.8906 7.25H32.6094C33.2422 7.25 33.875 7.88281 33.875 8.51562V26.2344C33.875 26.9727 33.2422 27.5 32.6094 27.5H14.8906ZM17 10.625V24.125H30.5V10.625H17ZM13.625 32.9844V32.1406C13.625 31.5078 14.1523 30.875 14.8906 30.875H32.6094C33.2422 30.875 33.875 31.5078 33.875 32.1406V32.9844C33.875 33.7227 33.2422 34.25 32.6094 34.25H14.8906C14.1523 34.25 13.625 33.7227 13.625 32.9844ZM37.25 32.9844V32.1406C37.25 31.5078 37.7773 30.875 38.5156 30.875H52.8594C53.4922 30.875 54.125 31.5078 54.125 32.1406V32.9844C54.125 33.7227 53.4922 34.25 52.8594 34.25H38.5156C37.7773 34.25 37.25 33.7227 37.25 32.9844ZM37.25 26.2344V25.3906C37.25 24.7578 37.7773 24.125 38.5156 24.125H52.8594C53.4922 24.125 54.125 24.7578 54.125 25.3906V26.2344C54.125 26.9727 53.4922 27.5 52.8594 27.5H38.5156C37.7773 27.5 37.25 26.9727 37.25 26.2344ZM37.25 12.7344V11.8906C37.25 11.2578 37.7773 10.625 38.5156 10.625H52.8594C53.4922 10.625 54.125 11.2578 54.125 11.8906V12.7344C54.125 13.4727 53.4922 14 52.8594 14H38.5156C37.7773 14 37.25 13.4727 37.25 12.7344ZM37.25 19.4844V18.6406C37.25 18.0078 37.7773 17.375 38.5156 17.375H52.8594C53.4922 17.375 54.125 18.0078 54.125 18.6406V19.4844C54.125 20.2227 53.4922 20.75 52.8594 20.75H38.5156C37.7773 20.75 37.25 20.2227 37.25 19.4844Z" />
  </svg>
);

export default EmptyPost;
