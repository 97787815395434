"use client";

import { Badge, Button, Group } from "@mantine/core";
import { useCounterStore } from "@/stores";

export function Counter() {
  const { count, increment, decrement } = useCounterStore();

  return (
    <div>
      <Group justify="center" mb="xl">
        <Badge size="xl" variant="light">
          Counter: {count}
        </Badge>
      </Group>

      <Group justify="center">
        <Button variant="filled" size="md" onClick={increment}>
          Increment
        </Button>
        <Button variant="light" size="md" onClick={decrement}>
          Decrement
        </Button>
      </Group>
    </div>
  );
}
