'use client';

import { useState } from 'react';
import {
  Button,
  TextInput,
  Select,
  NumberInput,
  Stack,
  Group,
  Collapse,
  Switch,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { IconChevronDown, IconTrash } from '@tabler/icons-react';
import {
  Debt,
  debtCategories,
  debtCategoryLabels,
  MinimumPaymentCalculationType,
  PaymentFrequencyType,
  paymentFrequencyTypeLabel,
} from '@/types';
import {
  formatDateToISO,
  isNullOrUndefined,
  parseISOToDate,
  setNullToUndefined,
} from '@/utils';

const minimumPaymentCalculationTypes = [
  { value: MinimumPaymentCalculationType.FixedAmount, label: 'Fixed Amount' },
  {
    value: MinimumPaymentCalculationType.PercentOfPrincipal,
    label: 'Percent of Principal',
  },
  {
    value: MinimumPaymentCalculationType.PercentOfPrincipalAndInterest,
    label: 'Percent of Principal & Interest',
  },
];

const paymentFrequencyTypes = [
  {
    value: PaymentFrequencyType.Monthly,
    label: paymentFrequencyTypeLabel[PaymentFrequencyType.Monthly],
  },
  {
    value: PaymentFrequencyType.DayInterval,
    label: paymentFrequencyTypeLabel[PaymentFrequencyType.DayInterval],
  },
];

interface DebtFormProps {
  initialValues?: Partial<Omit<Debt, 'id'>>;
  onSubmit: (debt: Omit<Debt, 'id'>) => void;
  onCancel: () => void;
  onDelete?: () => void;
  submitLabel?: string;
  isLoading?: boolean;
  isEdit?: boolean;
}

/**
 * Form component for adding or editing a debt.
 *
 * @param {Partial<Omit<Debt, 'id'>>} [initialValues] - Initial values for the form. Defaults to a debt with a fixed minimum payment calculation and a monthly payment frequency.
 * @param {Function} onSubmit - Called when the form is submitted, with the form values as an argument.
 * @param {Function} onCancel - Called when the form is cancelled.
 * @param {Function} [onDelete] - Called when the "Delete" button is clicked.
 * @param {string} [submitLabel] - The label for the "Submit" button. Defaults to "Submit".
 * @param {boolean} [isLoading] - Whether the form is currently saving or not.
 * @returns {ReactElement} The form component.
 */
export function DebtForm({
  initialValues = {
    minimumPaymentCalculationType: MinimumPaymentCalculationType.FixedAmount,
    paymentFrequencyType: PaymentFrequencyType.Monthly,
  },
  onSubmit,
  onCancel,
  onDelete,
  submitLabel = 'Submit',
  isLoading,
  isEdit,
}: DebtFormProps) {
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const [hasPromoApr, setHasPromoApr] = useState(
    !isNullOrUndefined(initialValues.promoApr)
  );

  // Prep form values
  const initialFormValues = {
    ...initialValues,
    name: initialValues.name || '',
    category: setNullToUndefined(initialValues.category),
    apr: setNullToUndefined(initialValues.apr),
    initialBalance: setNullToUndefined(initialValues.initialBalance),
    initialNextPaymentDueDate: parseISOToDate(
      initialValues.initialNextPaymentDueDate
    ),
    minimumPaymentCalculationType: setNullToUndefined(
      initialValues.minimumPaymentCalculationType
    ),
    minimumPaymentFixed: setNullToUndefined(initialValues.minimumPaymentFixed),
    minimumPaymentPercent: setNullToUndefined(
      initialValues.minimumPaymentPercent
    ),
    lowestPaymentAllowed: setNullToUndefined(
      initialValues.lowestPaymentAllowed
    ),
    paymentFrequencyType:
      initialValues.paymentFrequencyType || PaymentFrequencyType.Monthly,
    paymentIntervalDays: setNullToUndefined(initialValues.paymentIntervalDays),
    promoApr: setNullToUndefined(initialValues.promoApr),
    promoAprEndDate: parseISOToDate(initialValues.promoAprEndDate),
    note: initialValues.note || '',
  };

  const form = useForm({
    initialValues: initialFormValues,
    validate: {
      name: (value) => !value && 'Name is required',
      category: (value) => !value && 'Category is required',
      apr: (value) => {
        if (value || value === 0) return null;
        return 'APR is required';
      },
      initialBalance: (value) => !value && 'Current balance is required',
      initialNextPaymentDueDate: (value) =>
        !value && 'Next payment due date is required',
      paymentFrequencyType: (value) =>
        !value && 'Payment frequency is required',
      paymentIntervalDays: (value) => {
        if (
          form.values.paymentFrequencyType === PaymentFrequencyType.DayInterval
        ) {
          if (!value || (typeof value === 'number' && value < 1)) {
            return 'Payment interval must be at least 1 day';
          }
        }
        return null;
      },
      minimumPaymentCalculationType: (value) =>
        !value && 'Minimum payment calculation type is required',
      minimumPaymentFixed: (value) => {
        if (
          form.values.minimumPaymentCalculationType ===
          MinimumPaymentCalculationType.FixedAmount
        ) {
          if (value || value === 0) return null;
          return 'Minimum payment is required';
        }
        return null;
      },
      minimumPaymentPercent: (value) =>
        value !== undefined &&
        value !== null &&
        typeof value === 'number' &&
        (value <= 0 || value > 100) &&
        'Percentage must be between 0 and 100',
      lowestPaymentAllowed: (value) => {
        if (
          form.values.minimumPaymentCalculationType !==
          MinimumPaymentCalculationType.FixedAmount
        ) {
          if (
            value !== undefined &&
            value !== null &&
            typeof value === 'number' &&
            value < 0
          ) {
            return 'Lowest payment allowed must be positive';
          }
        }
        return null;
      },
      promoApr: (value) => {
        if (!hasPromoApr) return null;
        if (value || value === 0) return null;
        return 'Promotional APR is required';
      },
      promoAprEndDate: (value) => {
        if (!hasPromoApr) return null;
        if (value) return null;
        return 'Promotional APR end date is required';
      },
    },
  });

  const handleSubmit = form.onSubmit((values) => {
    const submissionValues = {
      ...values,
      name: values.name?.trim() || null,
      note: values.note?.trim() || null,
      initialNextPaymentDueDate: formatDateToISO(
        values.initialNextPaymentDueDate,
        false
      ),
      promoApr: hasPromoApr ? values.promoApr : null,
      promoAprEndDate: hasPromoApr
        ? formatDateToISO(values.promoAprEndDate, false)
        : null,
    };
    onSubmit(submissionValues as Omit<Debt, 'id'>);
  });

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Stack>
        <TextInput
          label="Debt name"
          placeholder="Enter debt name"
          autoComplete="off"
          {...form.getInputProps('name')}
        />
        <Select
          label="Category"
          placeholder="Select category"
          data={debtCategories.map((category) => ({
            value: category,
            label: debtCategoryLabels[category],
          }))}
          comboboxProps={{ zIndex: 1000 }}
          autoComplete="off"
          {...form.getInputProps('category')}
        />
        {!isEdit ? (
          <>
            <NumberInput
              label="Current balance"
              placeholder="Enter current balance"
              prefix="$"
              min={0}
              max={100000000}
              decimalScale={2}
              thousandSeparator=","
              inputMode="decimal"
              autoComplete="off"
              {...form.getInputProps('initialBalance')}
            />
          </>
        ) : null}
        <NumberInput
          label="Annual percentage rate (APR)"
          placeholder="Enter APR"
          description={
            isEdit
              ? 'Used to help calculate upcoming interest charges.'
              : 'This is the annual interest rate.'
          }
          suffix="%"
          min={0}
          max={100}
          decimalScale={4}
          inputMode="decimal"
          autoComplete="off"
          {...form.getInputProps('apr')}
        />
        {!isEdit ? (
          <DateInput
            label="Next payment due date"
            placeholder="Select date"
            minDate={new Date()}
            weekendDays={[]}
            popoverProps={{ zIndex: 1000 }}
            inputMode="none"
            autoComplete="off"
            {...form.getInputProps('initialNextPaymentDueDate')}
          />
        ) : null}
        <Collapse in={showOptionalFields}>
          <Stack>
            <Select
              label="Minimum payment calculation"
              data={minimumPaymentCalculationTypes}
              allowDeselect={false}
              comboboxProps={{ zIndex: 1000 }}
              autoComplete="off"
              {...form.getInputProps('minimumPaymentCalculationType')}
            />
          </Stack>
        </Collapse>
        {form.values.minimumPaymentCalculationType ===
          MinimumPaymentCalculationType.FixedAmount && (
          <NumberInput
            label="Minimum payment"
            placeholder="Enter minimum payment"
            description={
              isEdit
                ? 'Used to help calculate your debt payoff plan but debt updates take precedence.'
                : 'Rough estimate is okay.'
            }
            prefix="$"
            thousandSeparator=","
            min={0}
            max={form.getInputProps('initialBalance').value || 100000000}
            decimalScale={2}
            inputMode="decimal"
            autoComplete="off"
            {...form.getInputProps('minimumPaymentFixed')}
          />
        )}
        {(form.values.minimumPaymentCalculationType ===
          MinimumPaymentCalculationType.PercentOfPrincipal ||
          form.values.minimumPaymentCalculationType ===
            MinimumPaymentCalculationType.PercentOfPrincipalAndInterest) && (
          <>
            <NumberInput
              label="Minimum payment percentage"
              placeholder="0"
              suffix="%"
              thousandSeparator=","
              min={0}
              max={100}
              decimalScale={4}
              inputMode="decimal"
              autoComplete="off"
              {...form.getInputProps('minimumPaymentPercent')}
            />
            <NumberInput
              label="Lowest payment allowed"
              placeholder="Enter lowest payment allowed"
              prefix="$"
              thousandSeparator=","
              min={0}
              max={100000000}
              decimalScale={2}
              inputMode="decimal"
              autoComplete="off"
              {...form.getInputProps('lowestPaymentAllowed')}
            />
          </>
        )}

        <Collapse in={showOptionalFields}>
          <Stack>
            {/* <Select
              label="Payment Frequency"
              data={paymentFrequencyTypes}
              allowDeselect={false}
              comboboxProps={{ zIndex: 1000 }}
              autoComplete="off"
              {...form.getInputProps('paymentFrequencyType')}
            />
            {form.values.paymentFrequencyType ===
              PaymentFrequencyType.DayInterval && (
              <NumberInput
                label="Days Between Payments"
                min={1}
                inputMode="decimal"
                autoComplete="off"
                {...form.getInputProps('paymentIntervalDays')}
              />
            )} */}
            <Switch
              label="Has promotional APR"
              mt="xs"
              checked={hasPromoApr}
              onChange={(event) => {
                setHasPromoApr(event.currentTarget.checked);
                if (!event.currentTarget.checked) {
                  form.setFieldValue('promoApr', undefined);
                  form.setFieldValue('promoAprEndDate', undefined);
                }
              }}
              autoComplete="off"
            />
            {hasPromoApr && (
              <>
                <NumberInput
                  label="Promotional APR"
                  placeholder="Enter promotional APR"
                  suffix="%"
                  min={0}
                  max={100}
                  decimalScale={4}
                  inputMode="decimal"
                  autoComplete="off"
                  {...form.getInputProps('promoApr')}
                />
                <DateInput
                  label="Promotional APR end date"
                  placeholder="Select date"
                  weekendDays={[]}
                  popoverProps={{ zIndex: 1000 }}
                  clearable
                  inputMode="none"
                  autoComplete="off"
                  {...form.getInputProps('promoAprEndDate')}
                />
              </>
            )}
            <TextInput
              label="Note"
              placeholder="Add an optional note"
              maxLength={200}
              autoComplete="off"
              {...form.getInputProps('note')}
            />
          </Stack>
        </Collapse>

        <Button
          variant="subtle"
          onClick={() => setShowOptionalFields(!showOptionalFields)}
          rightSection={
            <IconChevronDown
              style={{
                transform: showOptionalFields ? 'rotate(180deg)' : 'none',
                transition: 'transform 200ms ease',
              }}
            />
          }
        >
          {showOptionalFields ? 'Hide' : 'Show'} optional fields
        </Button>

        <Group
          justify={onDelete ? 'space-between' : 'flex-end'}
          align="center"
          mt="md"
          w="100%"
        >
          {onDelete && (
            <Button
              variant="subtle"
              onClick={onDelete}
              py={4}
              px="xs"
              color="red.4"
            >
              <IconTrash size={24} />
            </Button>
          )}

          <Group justify="flex-end">
            <Button variant="light" onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>
            <Button type="submit" loading={isLoading}>
              {submitLabel}
            </Button>
          </Group>
        </Group>
      </Stack>
    </form>
  );
}
