'use client';

import { useEffect, useMemo, useState } from 'react';
import { Button, NumberInput, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { format } from 'date-fns';
import { NextDebtUpdate, Plan, PlanScheduleItem } from '@/types';
import { formatCurrency, getCanCreateNextDebtUpdate } from '@/utils';
import { useFinex } from '@/hooks';
import { Modal, CongratsPaymentModal } from '@/components';

interface MarkAsCompleteModalProps {
  plan: Plan;
  item: PlanScheduleItem;
  isOpened: boolean;
  onClose: () => void;
}

export function MarkAsCompleteModal({
  plan,
  item,
  isOpened,
  onClose,
}: MarkAsCompleteModalProps) {
  const { plans } = useFinex();
  const [error, setError] = useState<string | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [balanceBefore, setBalanceBefore] = useState<number | null>(
    item.balanceBefore ?? null
  );
  const [payment, setPayment] = useState<number | null>(
    item.totalPayment ?? null
  );

  const [
    congratsModalOpened,
    { open: openCongratsModal, close: closeCongratsModal },
  ] = useDisclosure(false);

  useEffect(() => {
    if (!isOpened) {
      setBalanceBefore(item.balanceBefore ?? null);
      setPayment(item.totalPayment ?? null);
    }
  }, [item, isOpened]);

  const { canCreateNextDebtUpdate, nextDebtUpdate } = useMemo(
    () => getCanCreateNextDebtUpdate(plan, item),
    [plan, item]
  );

  const handleClose = () => {
    setError(null);
    onClose();
    setBalanceBefore(null);
    setPayment(null);
  };

  const handleSuccess = (payment: number | null) => {
    setIsLoading(false);
    onClose();
    if (payment !== null && payment > 0) {
      openCongratsModal();
    }
  };

  const handleError = (errorMessage: string) => {
    setError(errorMessage);
    setIsLoading(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (item.debtUpdateId) {
      const updateRes = await plans.updateDebtUpdate(item.debtUpdateId, {
        balance: balanceBefore,
        payment,
      });
      if (updateRes.error) {
        handleError(updateRes.error);
        return;
      }
      handleSuccess(payment);
    } else if (
      canCreateNextDebtUpdate &&
      nextDebtUpdate &&
      nextDebtUpdate.debtId
    ) {
      const createRes = await plans.createDebtUpdate({
        ...nextDebtUpdate,
        debtId: nextDebtUpdate.debtId,
        balance: balanceBefore,
        payment,
      });
      if (createRes.error) {
        handleError(createRes.error);
        return;
      }
      handleSuccess(payment);
    }
  };

  const balanceAfterPayment = useMemo(() => {
    if (balanceBefore !== null) {
      const value = Number(balanceBefore) - Number(payment);
      return Math.max(value, 0);
    }
    return null;
  }, [balanceBefore, payment]);

  return (
    <>
      <Modal
        isOpened={isOpened}
        onClose={handleClose}
        title={
          <Text
            component="span"
            w="100%"
            display="inline-block"
            fw={700}
            size="xl"
            c="green.9"
            lh={1.4}
          >
            Mark as complete 🎉
          </Text>
        }
        error={error}
      >
        <form onSubmit={handleSubmit}>
          <Stack gap="md">
            <Text size="sm" lh={1.6}>
              Payment for{' '}
              <Text component="span" fw={600} c="blue.7">
                {item.debtName}
              </Text>{' '}
              <br />
              Due on{' '}
              <Text component="span" fw={600} c="blue.7">
                {format(item.date, 'MMM d, yyyy')}
              </Text>
            </Text>

            <NumberInput
              label="Balance before payment"
              placeholder="Enter balance before payment"
              prefix="$"
              min={0}
              max={100000000}
              decimalScale={2}
              thousandSeparator=","
              inputMode="decimal"
              autoComplete="off"
              value={balanceBefore ?? undefined}
              onChange={(value) => {
                if (value === '') {
                  setBalanceBefore(null);
                  return;
                }
                setBalanceBefore(Number(value));
              }}
            />

            <Stack gap={6}>
              <NumberInput
                label="Your actual payment"
                placeholder="Enter payment"
                prefix="$"
                min={0}
                max={balanceBefore || 100000000}
                decimalScale={2}
                thousandSeparator=","
                inputMode="decimal"
                autoComplete="off"
                value={payment ?? undefined}
                onChange={(value) => {
                  if (value === '') {
                    setPayment(null);
                    return;
                  }
                  setPayment(Number(value));
                }}
              />
              <Text size="xs" c="dimmed">
                Balance after payment:{' '}
                {balanceAfterPayment !== null
                  ? formatCurrency(balanceAfterPayment)
                  : '--'}
              </Text>
            </Stack>

            <Button
              type="submit"
              loading={isLoading}
              disabled={balanceBefore === null || payment === null}
              color="green.8"
              mt={6}
              size="md"
            >
              Submit
            </Button>
          </Stack>
        </form>
      </Modal>

      <CongratsPaymentModal
        isOpened={congratsModalOpened}
        onClose={closeCongratsModal}
      />
    </>
  );
}
