import { cn } from '@/utils';

export function GradientText({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <span
      className={cn(
        'text-transparent bg-clip-text bg-gradient-to-r bg-300',
        'from-purple-600 via-blue-600 to-green-600',
        'animate-gradient',
        className
      )}
    >
      {children}
    </span>
  );
}
