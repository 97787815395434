import React from 'react';

const Svg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="84" height="84" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M63.002 31.725a10.672 10.672 0 00-3.15-7.574 10.987 10.987 0 00-15.17 0L3.153 65.692a10.724 10.724 0 0015.155 15.159l41.545-41.542a10.664 10.664 0 003.15-7.584zM13.358 75.909a3.805 3.805 0 01-5.25 0 3.717 3.717 0 010-5.25l27.191-27.195 5.268 5.267-27.21 27.178zM54.91 34.357l-9.408 9.411-5.25-5.267 9.411-9.408a3.72 3.72 0 115.25 5.25l-.003.014zM17.022 9.993l5.425-1.547 1.547-5.425a4.169 4.169 0 018.015 0l1.547 5.425 5.425 1.547a4.169 4.169 0 010 8.015l-5.425 1.547-1.547 5.425a4.169 4.169 0 01-8.015 0l-1.547-5.425-5.425-1.547a4.169 4.169 0 010-8.015zm63.96 50.015l-5.426 1.547-1.547 5.425a4.169 4.169 0 01-8.015 0l-1.547-5.425-5.425-1.547a4.169 4.169 0 010-8.015l5.425-1.547 1.547-5.425a4.169 4.169 0 018.015 0l1.547 5.425 5.425 1.547a4.169 4.169 0 010 8.015zM62.143 8.751l4.746-1.355 1.362-4.753a3.647 3.647 0 017 0l1.354 4.746 4.746 1.355a3.647 3.647 0 010 7l-4.746 1.354-1.354 4.757a3.647 3.647 0 01-7 0l-1.355-4.743-4.753-1.361a3.647 3.647 0 010-7z"
      fill="#1054DE"
    />
  </svg>
);

export default Svg;
