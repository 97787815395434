'use client';

import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Gleap from 'gleap';
import {
  AppShell,
  Group,
  NavLink,
  Stack,
  Title,
  Text,
  Paper,
  UnstyledButton,
  rem,
  Box,
  Badge,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import {
  IconHome,
  IconCopyCheck,
  IconUsers,
  IconSettings,
  IconHelp,
} from '@tabler/icons-react';
import { CookieName } from '@/types';
import { getInviteConfigBySlug } from '@/utils';
import { usePreferencesStore } from '@/stores';
import {
  useFinex,
  UseUserReturn,
  UseStripeStateReturn,
  useInstantNavigation,
  usePlanReadyCount,
} from '@/hooks';
import { DebtFreeCountdownCard } from './DebtFreeCountdownCard';
import { CenteredLoader } from './CenteredLoader';
import { FinexLogo } from './FinexLogo';
import { ImpersonationHeader } from './ImpersonationHeader';
import { InstantLink } from './InstantLink';

interface ShellProps {
  children: React.ReactNode;
}

const SHELL_BLACKLISTED_PATHNAMES = [
  '/start',
  '/membership/inactive',
  '/logout',
];

const GLEAP_BLACKLISTED_PATHNAMES = ['/start', '/signup'];

function getIsShellEnabled(
  user: UseUserReturn,
  stripeState: UseStripeStateReturn,
  currentPathname: string
) {
  if (!currentPathname) {
    return false;
  }
  if (
    !user.isReady ||
    !user.data ||
    !stripeState.isReady ||
    !stripeState.isSubscriptionActive ||
    SHELL_BLACKLISTED_PATHNAMES.includes(currentPathname) ||
    getInviteConfigBySlug(currentPathname.slice(1))
  ) {
    return false;
  }
  return true;
}

export function Shell({ children }: ShellProps) {
  const instantNavigation = useInstantNavigation();
  const isMobile = useMediaQuery('(max-width: 48em)');
  const { user, profile, stripeState } = useFinex();
  const { colorScheme } = usePreferencesStore();
  const [opened, { toggle }] = useDisclosure();
  const isShellEnabled = getIsShellEnabled(
    user,
    stripeState,
    instantNavigation.currentPathname
  );
  const [isGleapOpened, setIsGleapOpened] = useState(false);
  const [isImpersonation, setIsImpersonation] = useState(false);
  const readyCount = usePlanReadyCount();

  useEffect(() => {
    Gleap.on('open', () => {
      setIsGleapOpened(true);
    });
    Gleap.on('close', () => {
      setIsGleapOpened(false);
    });
    if (Cookies.get(CookieName.FinexAdminIsImpersonation) === 'true') {
      setIsImpersonation(true);
    }
  }, []);

  useEffect(() => {
    if (!user.isReady || !profile.isReady || !stripeState.isReady) {
      Gleap.showFeedbackButton(false);
    } else if (isShellEnabled) {
      Gleap.showFeedbackButton(isMobile === false);
    } else {
      Gleap.showFeedbackButton(
        !GLEAP_BLACKLISTED_PATHNAMES.includes(instantNavigation.currentPathname)
      );
    }
  }, [
    user,
    profile,
    stripeState,
    isShellEnabled,
    instantNavigation.currentPathname,
    isMobile,
  ]);

  const navItems = [
    { label: 'Home', url: '/', icon: IconHome },
    {
      label: 'Plan',
      url: profile.data?.defaultPlanId
        ? `/plans/${profile.data.defaultPlanId}?tab=progress`
        : '/plans',
      icon: IconCopyCheck,
      isActive: function () {
        return (
          instantNavigation.currentUrl === this.url ||
          instantNavigation.currentUrl?.startsWith(
            `/plans/${profile.data?.defaultPlanId}`
          )
        );
      },
      badgeCount: readyCount,
    },
    {
      label: 'Community',
      url: '/community',
      icon: IconUsers,
    },
    {
      label: 'Help',
      icon: IconHelp,
      onClick: () => {
        if (Gleap.isOpened()) {
          Gleap.close();
          setIsGleapOpened(false);
        } else {
          Gleap.open();
          setIsGleapOpened(true);
        }
      },
      isActive: () => isGleapOpened,
    },
    {
      label: 'Settings',
      url: '/settings',
      icon: IconSettings,
    },
  ];

  if (!user.isReady || (user.data && !stripeState.isReady)) {
    return <CenteredLoader />;
  }

  return (
    <AppShell
      header={{ height: isImpersonation ? 90 : 60 }}
      navbar={{
        width: `calc(250px + var(--safe-area-left))`,
        breakpoint: 'sm',
        collapsed: { mobile: !opened },
      }}
      styles={(theme) => ({
        root: {
          backgroundColor: isShellEnabled
            ? colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0]
            : 'transparent',
          paddingBottom: isShellEnabled
            ? isMobile
              ? `calc(${rem(100)} + var(--safe-area-bottom))`
              : `calc(${rem(40)} + var(--safe-area-bottom))`
            : 0, // Add padding for bottom navigation
          paddingRight: `var(--safe-area-right)`,
        },
        header: {
          backgroundColor:
            colorScheme === 'dark' ? theme.colors.dark[8] : 'white',
          boxShadow: isMobile
            ? colorScheme === 'dark'
              ? '0px 4px 10px rgba(0, 0, 0, 0.1)'
              : '0px 4px 10px rgba(0, 0, 0, 0.05)'
            : undefined,
          border: isMobile ? 'none' : undefined,
          paddingLeft: `var(--safe-area-left)`,
          paddingRight: `var(--safe-area-right)`,
        },
        navbar: {
          backgroundColor:
            colorScheme === 'dark' ? theme.colors.dark[8] : 'white',
          paddingLeft: `calc(16px + var(--safe-area-left))`,
        },
      })}
      disabled={!isShellEnabled}
    >
      <AppShell.Header>
        {isImpersonation ? <ImpersonationHeader /> : null}
        <Group h={60} px="md" justify="space-between">
          <Group gap="xs" wrap="nowrap">
            <InstantLink href="/">
              <Group gap="xs" wrap="nowrap">
                <FinexLogo size={40} />
                <Title order={3}>Finex</Title>
              </Group>
            </InstantLink>
          </Group>
          {user.data && (
            <Stack visibleFrom="sm">
              <Text size="sm" c="dimmed">
                {user.data?.email}
              </Text>
            </Stack>
          )}
        </Group>
      </AppShell.Header>

      <AppShell.Navbar py="sm" pr="md" style={{ overflow: 'auto' }}>
        <Stack justify="space-between" flex={1}>
          <Stack gap="xs">
            <DebtFreeCountdownCard visibleFrom="sm" mb={5} />
            {navItems.map((item) => {
              const isActive =
                item.isActive?.() || instantNavigation.currentUrl === item.url;
              return (
                <NavLink
                  key={item.label}
                  component={InstantLink}
                  href={item.url ?? ''}
                  label={item.label}
                  leftSection={<item.icon size="1.2rem" stroke={1.5} />}
                  rightSection={
                    item.badgeCount ? (
                      <Badge size="md" miw={20} px={6}>
                        {item.badgeCount.toLocaleString()}
                      </Badge>
                    ) : null
                  }
                  active={isActive}
                  className="rounded-lg"
                  onClick={() => {
                    item.onClick?.();
                    if (opened) {
                      toggle();
                    }
                  }}
                />
              );
            })}
          </Stack>

          <Stack gap="xs">
            <Group
              align="center"
              justify="center"
              style={{ marginTop: 'auto' }}
            >
              <Text
                size="9px"
                c="dimmed"
                lh="h1"
                component={InstantLink}
                href="/terms"
              >
                Terms of Use
              </Text>
              <Text
                size="9px"
                c="dimmed"
                lh="h1"
                component={InstantLink}
                href="/privacy"
              >
                Privacy Policy
              </Text>
            </Group>
            <Text ta="center" size="9px" c="dimmed" lh="h1">
              &copy; {new Date().getFullYear()} Cavern Software
            </Text>
          </Stack>
        </Stack>
      </AppShell.Navbar>

      <AppShell.Main>
        {isImpersonation && !isShellEnabled ? <ImpersonationHeader /> : null}
        {instantNavigation.isLoading ? <CenteredLoader /> : children}
        {isShellEnabled && (
          <Paper
            hiddenFrom="sm"
            pos="fixed"
            bottom={0}
            left={0}
            right={0}
            px={10}
            pt={8}
            withBorder
            style={() => ({
              zIndex: 1000,
              // backdropFilter: 'blur(10px)',
              // WebkitBackdropFilter: 'blur(10px)', // For Safari support
              // backgroundColor:
              //   colorScheme === 'dark'
              //     ? 'rgba(36, 36, 36, 0.75)'
              //     : 'rgba(255, 255, 255, 0.75)',
              backgroundColor: 'white',
              borderRight: 'none',
              borderLeft: 'none',
              borderBottom: 'none',
              paddingBottom: `calc(10px + var(--safe-area-bottom))`,
            })}
            radius={0}
            shadow="md"
          >
            <Group justify="space-evenly" gap={0}>
              {navItems.map((item) => {
                const Icon = item.icon;
                const isActive =
                  item.isActive?.() ||
                  instantNavigation.currentUrl === item.url;
                return (
                  <Box flex={1} key={item.label}>
                    <UnstyledButton
                      component={InstantLink}
                      href={item.url ?? ''}
                      onClick={() => {
                        item.onClick?.();
                      }}
                    >
                      <Stack align="center" gap={4} pos="relative">
                        <Box pos="relative">
                          {item.badgeCount ? (
                            <Badge
                              size="sm"
                              pos="absolute"
                              top={-4}
                              left={16}
                              miw={18}
                              px={0}
                              pl={1}
                            >
                              {item.badgeCount.toLocaleString()}
                            </Badge>
                          ) : null}
                          <Icon
                            size={24}
                            style={{
                              color: isActive
                                ? 'var(--mantine-color-blue-text)'
                                : 'var(--mantine-color-gray-7)',
                            }}
                          />
                        </Box>
                        <Text
                          size="12px"
                          c={
                            isActive
                              ? 'var(--mantine-color-blue-text)'
                              : 'var(--mantine-color-gray-7)'
                          }
                        >
                          {item.label}
                        </Text>
                      </Stack>
                    </UnstyledButton>
                  </Box>
                );
              })}
            </Group>
          </Paper>
        )}
      </AppShell.Main>
    </AppShell>
  );
}
