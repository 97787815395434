import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 0;
  overflow-y: auto;
`;

export const DeclineBanner = styled.div`
  background: ${({ theme }) => theme.palette.base.shade4};
  color: ${({ theme }) => theme.palette.base.shade1};
  margin-bottom: 14px;
  padding: 12px 16px;
  border-radius: 8px;

  @media (max-width: 48em) {
    border-radius: 0;
    border-right: none;
    border-left: none;
  }
`;
