import { Card, Group, Stack, Text } from '@mantine/core';
import { Icon, IconChevronCompactRight } from '@tabler/icons-react';
import { cn } from '@/utils';
import { InstantLink } from './InstantLink';

export interface StatsCardProps {
  id: string;
  Icon: Icon;
  title: string;
  value: string;
  subtitle: string;
  pb?: number | string;
  ml?: number | string;
  mr?: number | string;
  href?: string;
}

export function StatsCard({
  Icon,
  title,
  value,
  subtitle,
  pb,
  ml,
  mr,
  href,
}: StatsCardProps) {
  return (
    <Card
      className={cn({
        'transition-all duration-100 ease-in-out cursor-pointer active:translate-y-[2px] active:shadow-sm active:opacity-80 active:duration-0':
          href,
      })}
      withBorder
      shadow="md"
      radius="md"
      h="100%"
      pb={pb}
      ml={ml}
      mr={mr}
      pr={href ? 4 : undefined}
      component={href ? InstantLink : undefined}
      href={href ?? '/'}
    >
      <Group
        align="center"
        justify="space-between"
        wrap="nowrap"
        gap="xs"
        style={{ flex: 1 }}
        w="100%"
      >
        <Group style={{ flex: 1 }}>
          <Icon size={24} style={{ color: 'var(--mantine-color-blue-6)' }} />
          <Stack gap={0} w="100%">
            <Text size="sm" c="dimmed">
              {title}
            </Text>
            <Text fw={500} size="xl">
              {value}
            </Text>
            <Text size="sm" c="dimmed" style={{ whiteSpace: 'nowrap' }}>
              {subtitle}
            </Text>
          </Stack>
        </Group>
        {href && (
          <IconChevronCompactRight
            style={{ color: 'var(--mantine-color-gray-4)' }}
            size={30}
          />
        )}
      </Group>
    </Card>
  );
}
