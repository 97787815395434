'use client';

import { Stack, Text, Group } from '@mantine/core';
import { useFinex } from '@/hooks';
import { InstantLink } from './InstantLink';

export function LegalFooter() {
  const { profile } = useFinex();
  return (
    <Stack gap="xs" my="xl" opacity={0.8}>
      <Group align="center" justify="center" gap={8}>
        <Text
          size="10px"
          c="dimmed"
          lh="h1"
          component={InstantLink}
          href="/terms"
        >
          Terms of Use
        </Text>
        <Text opacity={0.4} size="xs">
          •
        </Text>
        <Text
          size="10px"
          c="dimmed"
          lh="h1"
          component={InstantLink}
          href="/privacy"
        >
          Privacy Policy
        </Text>
        {profile.data?.role === 'admin' ? (
          <>
            <Text opacity={0.4} size="xs">
              •
            </Text>
            <Text
              size="10px"
              c="dimmed"
              lh="h1"
              component={InstantLink}
              href="/admin"
            >
              Admin
            </Text>
          </>
        ) : null}
      </Group>
      <Text ta="center" size="10px" c="dimmed" lh="h1">
        &copy; {new Date().getFullYear()} Cavern Software. All rights reserved.
      </Text>
    </Stack>
  );
}
