'use client';

import { useState } from 'react';
import Cookies from 'js-cookie';
import {
  Container,
  Title,
  Text,
  Stack,
  Group,
  Button,
  TextInput,
  Alert,
} from '@mantine/core';
import { usePreferencesStore } from '@/stores';
import { FinexLogo } from './FinexLogo';

const BYPASS_PASSWORD = 'finex2025';
const COOKIE_NAME = 'finex-bypass';

export function ComingSoon() {
  const { colorScheme } = usePreferencesStore();
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setError(false);
    setIsLoading(true);
    if (password === BYPASS_PASSWORD) {
      Cookies.set(COOKIE_NAME, 'true', { expires: 7 });
      window.location.reload();
    } else {
      setTimeout(() => {
        setError(true);
        setIsLoading(false);
      }, 1000);
    }
  };

  return (
    <Container
      py={120}
      bg={colorScheme === 'dark' ? 'dark' : 'white'}
      maw="none"
      h="100%"
    >
      <Stack align="center" gap="lg">
        <Group gap="xs">
          <FinexLogo size={48} />
          <Title>Finex</Title>
        </Group>
        <Text c="dimmed" ta="center" maw={260}>
          We&apos;re building a better way to crush debt,{' '}
          <span className="font-bold">together</span>.
        </Text>
        <Text c="dimmed" ta="center" mb="sm">
          <i>Coming soon.</i>
        </Text>

        {error && (
          <Text c="orange.9" size="xs">
            Invalid password.
          </Text>
        )}

        <form onSubmit={handleSubmit}>
          <Stack gap="sm" style={{ maxWidth: 300 }}>
            <TextInput
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
            <Button
              type="submit"
              fullWidth
              loading={isLoading}
              disabled={!password}
            >
              Access site
            </Button>
          </Stack>
        </form>

        <Text ta="center" size="10px" c="dimmed" lh="h1" mt="sm">
          &copy; {new Date().getFullYear()} Cavern Software. All rights
          reserved.
        </Text>
      </Stack>
    </Container>
  );
}
