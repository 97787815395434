import React from 'react';

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#fff"
        d="M11.121 5.781c.527-.527 1.441-.176 1.441.598v11.777c0 .774-.914 1.125-1.44.598l-3.13-3.129H4.406c-.492 0-.843-.352-.843-.844V9.72c0-.457.351-.844.843-.844h3.586l3.13-3.094zm9.316 6.469c0 2.25-1.16 4.29-3.023 5.52-.422.246-.95.105-1.16-.282a.845.845 0 01.246-1.16 4.807 4.807 0 002.25-4.078 4.792 4.792 0 00-2.25-4.043.845.845 0 01-.246-1.16.824.824 0 011.16-.281c1.863 1.23 3.023 3.27 3.023 5.484zm-4.992-2.672c.985.527 1.617 1.582 1.617 2.672 0 1.125-.632 2.18-1.617 2.707-.422.246-.914.105-1.16-.316a.869.869 0 01.352-1.16c.422-.247.738-.704.738-1.231 0-.492-.316-.95-.738-1.195a.869.869 0 01-.352-1.16c.246-.422.738-.563 1.16-.317z"
      ></path>
    </svg>
  );
}

export default Icon;
