'use client';

import { forwardRef } from 'react';
import Link, { LinkProps } from 'next/link';
import { useInstantNavigation } from '@/hooks';

export const InstantLink = forwardRef<
  HTMLAnchorElement,
  React.AnchorHTMLAttributes<HTMLAnchorElement> & LinkProps
>(({ href, onClick, ...props }, ref) => {
  const { push } = useInstantNavigation();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // Don't use instant navigation if any modifier key is pressed or if middle mouse button
    if (
      !e.metaKey &&
      !e.ctrlKey &&
      !e.altKey &&
      !e.shiftKey &&
      e.button !== 1
    ) {
      push(href.toString());
    }
    onClick?.(e);
  };

  return <Link ref={ref} href={href} onClick={handleClick} {...props} />;
});

InstantLink.displayName = 'InstantLink';
