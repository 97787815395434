'use client';

import { Card, Group, Progress, Stack, Text } from '@mantine/core';

export function PlanStepProgress({
  totalStepsCount,
  completedStepsCount,
}: {
  totalStepsCount: number;
  completedStepsCount: number;
}) {
  const progress = (completedStepsCount / totalStepsCount) * 100;
  let progressRounded = Math.round(progress);
  if (progressRounded === 0 && progress > 0) {
    progressRounded = 1;
  } else if (progressRounded === 100 && progress < 100) {
    progressRounded = 99;
  }

  return (
    <Card withBorder shadow="md" radius="md">
      <Stack gap="xs">
        <Group justify="space-between" align="flex-start">
          <Stack gap={0}>
            <Group gap="xs" justify="start" wrap="nowrap">
              <Text
                fw={800}
                size="28px"
                lh={1}
                c={progressRounded === 0 ? 'gray.7' : '#2e9e44'}
              >
                {progressRounded}%
              </Text>
              <Text size="xs" c="gray.7" lh={1.5}>
                You've completed{' '}
                <b>
                  {completedStepsCount} out of {totalStepsCount}
                </b>{' '}
                payments in this debt payoff plan.
              </Text>
            </Group>
          </Stack>
        </Group>

        <Progress.Root size="xl">
          <Progress.Section
            value={progressRounded}
            color="green.7"
            styles={{
              section: {
                borderRadius: '10px',
              },
            }}
          />
        </Progress.Root>
      </Stack>
    </Card>
  );
}
