import React from 'react';

interface FallbackIconProps extends React.SVGProps<SVGSVGElement> {
  backgroundColor?: string;
}

const FallbackReaction = ({ backgroundColor, ...props }: FallbackIconProps) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.6035 10.5C19.6035 15.7467 15.3502 20 10.1035 20C4.85681 20 0.603516 15.7467 0.603516 10.5C0.603516 5.25329 4.85681 1 10.1035 1C15.3502 1 19.6035 5.25329 19.6035 10.5Z"
      stroke="currentColor"
      fill={backgroundColor}
    />
    <path
      d="M10.2723 6.71429C11.6786 6.71429 13.202 7.83595 13.202 9.29242C13.202 11.2344 11.1094 11.2679 11.1094 11.9877V12.0714C11.1094 12.3058 10.9252 12.4732 10.7076 12.4732H9.48549C9.26786 12.4732 9.08371 12.3058 9.08371 12.0714V11.9208C9.08371 10.8828 9.87054 10.4643 10.4732 10.1295C10.9754 9.84487 11.2935 9.66072 11.2935 9.27568C11.2935 8.77345 10.6574 8.45537 10.1384 8.45537C9.48549 8.45537 9.16741 8.75671 8.74888 9.27568C8.61496 9.44309 8.36384 9.47657 8.19643 9.34264L7.47656 8.79019C7.29241 8.673 7.25893 8.42188 7.37612 8.25447C8.0625 7.26675 8.93304 6.71429 10.2723 6.71429ZM10.1049 12.9755C10.7411 12.9755 11.26 13.4944 11.26 14.1306C11.26 14.7667 10.7411 15.2857 10.1049 15.2857C9.45201 15.2857 8.93304 14.7667 8.93304 14.1306C8.93304 13.4944 9.45201 12.9755 10.1049 12.9755Z"
      fill="currentColor"
    />
  </svg>
);

export default FallbackReaction;
