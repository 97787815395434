import { Badge, Group, Tabs, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Icon } from '@tabler/icons-react';

export function Tab({
  value,
  tab,
  label,
  Icon,
  badgeCount,
}: {
  value: string;
  tab: string;
  label: string;
  Icon: Icon;
  badgeCount?: number;
}) {
  const isMobile = useMediaQuery('(max-width: 48em)');

  return (
    <Tabs.Tab
      value={value}
      leftSection={
        <Icon
          size={20}
          color={
            tab === value
              ? 'var(--mantine-color-blue-6)'
              : 'var(--mantine-color-gray-6)'
          }
        />
      }
      flex={1}
      styles={{
        tabSection: {
          margin: 0,
        },
      }}
    >
      <Group gap={0} wrap="nowrap">
        {!isMobile && (
          <Text
            size="sm"
            c={
              tab === value
                ? 'var(--mantine-color-blue-9)'
                : 'var(--mantine-color-gray-7)'
            }
            ml="xs"
          >
            {label}
          </Text>
        )}
        {badgeCount ? (
          <Badge size="md" miw={20} px={0} ml={isMobile ? 6 : 10}>
            {badgeCount.toLocaleString()}
          </Badge>
        ) : null}
      </Group>
    </Tabs.Tab>
  );
}
