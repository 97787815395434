import React from 'react';
import File from './File';

const Default = ({ color = '#999', ...props }: React.SVGProps<SVGSVGElement>) => (
  <File {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M16.4998 11.0549H7.49977c-.1656 0-.3-.1306-.3-.2916 0-.161.1344-.2917.3-.2917h9.00003c.1656 0 .3.1307.3.2917s-.1344.2916-.3.2916zM16.4998 12.8049H7.49977c-.1656 0-.3-.1306-.3-.2916 0-.161.1344-.2917.3-.2917h9.00003c.1656 0 .3.1307.3.2917s-.1344.2916-.3.2916zM16.4998 14.5549H7.49977c-.1656 0-.3-.1306-.3-.2916 0-.161.1344-.2917.3-.2917h9.00003c.1656 0 .3.1307.3.2917s-.1344.2916-.3.2916zM13.4998 16.3049H7.49977c-.1656 0-.3-.1306-.3-.2916 0-.161.1344-.2917.3-.2917h6.00003c.1656 0 .3.1307.3.2917s-.1344.2916-.3.2916zM13.4998 18.0549H7.49977c-.1656 0-.3-.1306-.3-.2916 0-.161.1344-.2917.3-.2917h6.00003c.1656 0 .3.1307.3.2917s-.1344.2916-.3.2916z"
      clipRule="evenodd"
    />
  </File>
);

export default Default;
