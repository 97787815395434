'use client';

import { Card, Group, SimpleGrid, Stack, Text } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import { format } from 'date-fns';
import {
  PlannedExtraDebtPayment,
  PlannedExtraDebtPaymentFrequencyType,
  plannedExtraDebtPaymentFrequencyTypeLabel,
} from '@/types';
import { formatCurrency } from '@/utils';

interface PlannedExtraDebtPaymentCardProps {
  payment: PlannedExtraDebtPayment;
  index: number;
  onClick?: () => void;
}

export function PlannedExtraDebtPaymentCard({
  payment,
  index,
  onClick,
}: PlannedExtraDebtPaymentCardProps) {
  return (
    <Card
      className="active:translate-y-[2px] active:shadow-sm active:opacity-80 transition-all active:duration-0 duration-100 ease-in-out cursor-pointer"
      withBorder
      shadow="md"
      radius="md"
      onClick={onClick}
    >
      <Group align="center" justify="space-between" wrap="nowrap" gap="xs">
        <Stack gap={6} style={{ flex: 1 }}>
          <Group align="center" justify="space-between" mb="xs">
            <Text size="sm" c="dimmed">
              #{index + 1}
            </Text>

            <IconEdit
              style={{
                color: 'var(--mantine-color-gray-5)',
                cursor: 'pointer',
              }}
              size={22}
            />
          </Group>

          <SimpleGrid cols={{ base: 2, md: 4 }}>
            <Stack gap={2}>
              <Text size="xs" c="dimmed">
                Amount
              </Text>
              <Text fw={500} size="sm">
                {formatCurrency(payment.amount)}
              </Text>
            </Stack>

            <Stack gap={2}>
              <Text size="xs" c="dimmed">
                Frequency
              </Text>
              <Text fw={500} size="sm">
                {
                  plannedExtraDebtPaymentFrequencyTypeLabel[
                    payment.frequencyType
                  ]
                }
                {payment.intervalDays ? (
                  <>
                    <br />
                    {payment.intervalDays && ` (${payment.intervalDays} days)`}
                  </>
                ) : null}
              </Text>
            </Stack>

            <Stack gap={2}>
              <Text size="xs" c="dimmed">
                {payment.frequencyType ===
                PlannedExtraDebtPaymentFrequencyType.OneTime ? (
                  <>Month</>
                ) : (
                  <>Starting month</>
                )}
              </Text>
              <Text fw={500} size="sm">
                {payment.startDate && format(payment.startDate, 'MMM yyyy')}
              </Text>
            </Stack>

            {payment.frequencyType !==
              PlannedExtraDebtPaymentFrequencyType.OneTime && (
              <Stack gap={2}>
                <Text size="xs" c="dimmed">
                  Ending month
                </Text>
                <Text fw={500} size="sm">
                  {payment.endDate
                    ? format(payment.endDate, 'MMM yyyy')
                    : 'Ongoing'}
                </Text>
              </Stack>
            )}
          </SimpleGrid>
        </Stack>
      </Group>
    </Card>
  );
}
