import { Group } from '@mantine/core';
import { Loader } from './Loader';

export function CenteredLoader() {
  return (
    <Group justify="center" py="xl">
      <Loader />
    </Group>
  );
}
