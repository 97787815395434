import React from 'react';

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_4792_23932)"></circle>
      <path
        fill="#fff"
        d="M7.486 10.575H5.42a.611.611 0 00-.62.619v6.187c0 .361.258.619.62.619h2.066c.336 0 .62-.258.62-.619v-6.187a.628.628 0 00-.62-.619zm-1.033 6.394a.596.596 0 01-.62-.619c0-.335.258-.619.62-.619.336 0 .62.284.62.619 0 .36-.284.619-.62.619zm8.264-10.055c0-1.908-1.24-2.114-1.859-2.114-.542 0-.775 1.031-.878 1.495-.155.567-.284 1.135-.672 1.521-.826.851-1.265 1.908-2.298 2.914a.415.415 0 00-.078.232v5.517c0 .155.13.284.284.31.414 0 .956.231 1.37.412.826.36 1.833.799 3.073.799h.077c1.11 0 2.428 0 2.944-.748.233-.309.285-.696.155-1.16.44-.438.646-1.263.44-1.933.439-.593.49-1.444.232-2.037.31-.31.516-.8.49-1.263 0-.8-.67-1.522-1.523-1.522h-2.635c.207-.721.878-1.34.878-2.423z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_4792_23932"
          x1="9"
          x2="19.8"
          y1="2.4"
          y2="29.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5B9DFF"></stop>
          <stop offset="1" stopColor="#0041BE"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Icon;
