import React from 'react';

function Verified(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <path d="M9.36823 15.4777C10.062 16.1767 10.7245 16.1715 11.4234 15.4777L12.2059 14.6953C12.2789 14.6223 12.3415 14.6014 12.4354 14.6014H13.5412C14.5271 14.6014 14.9966 14.1319 14.9966 13.1461V12.0402C14.9966 11.9463 15.0227 11.8785 15.0905 11.8107L15.8729 11.023C16.5719 10.3293 16.5667 9.66681 15.8729 8.97305L15.0905 8.19061C15.0227 8.11758 14.9966 8.05499 14.9966 7.9611V6.85525C14.9966 5.86938 14.5271 5.39991 13.5412 5.39991H12.4354C12.3415 5.39991 12.2737 5.37383 12.2059 5.30602L11.4234 4.52358C10.7245 3.82461 10.062 3.82461 9.36823 4.5288L8.58579 5.30602C8.51798 5.37383 8.45017 5.39991 8.35628 5.39991H7.25043C6.26456 5.39991 5.79509 5.85894 5.79509 6.85525V7.9611C5.79509 8.05499 5.76901 8.1228 5.7012 8.19061L4.91876 8.97305C4.21979 9.66681 4.225 10.3293 4.91876 11.023L5.7012 11.8107C5.76901 11.8785 5.79509 11.9463 5.79509 12.0402V13.1461C5.79509 14.1319 6.26456 14.6014 7.25043 14.6014H8.35628C8.45017 14.6014 8.51276 14.6223 8.58579 14.6953L9.36823 15.4777ZM9.52993 12.6714C9.34736 12.6714 9.20131 12.6036 9.08655 12.4836L7.41735 10.6162C7.32867 10.5223 7.28173 10.3919 7.28173 10.2615C7.28173 9.94849 7.50603 9.72419 7.82943 9.72419C8.00679 9.72419 8.13719 9.78157 8.25195 9.90676L9.50907 11.3099L12.0233 7.72115C12.1485 7.5438 12.2841 7.47077 12.4928 7.47077C12.8162 7.47077 13.0457 7.69507 13.0457 7.99761C13.0457 8.10193 13.004 8.23234 12.9257 8.34188L9.9994 12.4471C9.87942 12.6036 9.72293 12.6714 9.52993 12.6714Z" />
    </svg>
  );
}

export default Verified;
