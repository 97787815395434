'use client';

import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { ColorSchemeScript, MantineProvider } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import { Notifications, useNotifications } from '@mantine/notifications';
// import { usePreferencesStore } from '@/stores';
import { initializeGleapClient } from '@/utils/gleap';
import {
  AmityProvider,
  PostHogProvider,
  FinexAmityProvider,
} from '@/providers';
import {
  FinexProvider,
  useIsMounted,
  ConfettiProvider,
  InstantNavigationProvider,
} from '@/hooks';

const PostHogPageView = dynamic(() => import('../providers/PostHogPageView'), {
  ssr: false,
});

export function ClientProviders({ children }: { children: React.ReactNode }) {
  // const colorScheme = usePreferencesStore((state) => state.colorScheme);
  const isMounted = useIsMounted();
  const isMobile = useMediaQuery('(max-width: 48em)');

  useEffect(() => {
    initializeGleapClient();
  }, []);

  if (!isMounted) return null;

  return (
    <PostHogProvider>
      <PostHogPageView />
      <ColorSchemeScript defaultColorScheme="light" />
      <MantineProvider
        // forceColorScheme={colorScheme}
        defaultColorScheme="light"
        forceColorScheme="light"
        theme={{
          cursorType: 'pointer',
          defaultRadius: 'md',
        }}
      >
        <Notifications
          position="bottom-center"
          styles={{
            notification: {
              bottom: isMobile
                ? 'calc(60px + var(--safe-area-bottom))'
                : undefined,
            },
          }}
        />
        <ModalsProvider modalProps={{ zIndex: 1002 }}>
          <FinexProvider>
            <AmityProvider>
              <FinexAmityProvider>
                <InstantNavigationProvider>
                  <ConfettiProvider>{children}</ConfettiProvider>
                </InstantNavigationProvider>
              </FinexAmityProvider>
            </AmityProvider>
          </FinexProvider>
        </ModalsProvider>
      </MantineProvider>
    </PostHogProvider>
  );
}
