'use client';

import { useMemo } from 'react';
import { IconCash, IconSquareRoundedPercentage } from '@tabler/icons-react';
import { formatCurrency, getLatestDebtStats } from '@/utils';
import { usePayoffStats, usePlanSchedule } from '@/hooks';
import { Plan } from '@/types';
import { StatsCards } from './StatsCards';

export function PlanStatsCards({ plan }: { plan?: Plan }) {
  const planSchedule = usePlanSchedule(plan);
  const stats = usePayoffStats(planSchedule);

  const totalDebtBalance = useMemo(() => {
    return plan?.debts.reduce((total, debt) => {
      return total + (getLatestDebtStats(debt).currentBalance || 0);
    }, 0);
  }, [plan?.debts]);

  const totalStartingBalance = useMemo(() => {
    return plan?.debts.reduce((total, debt) => {
      return total + (getLatestDebtStats(debt).initialBalance || 0);
    }, 0);
  }, [plan?.debts]);

  const items = useMemo(
    () => [
      {
        id: 'balance',
        Icon: IconCash,
        title: 'Total debt balance',
        value: plan?.debts.length ? formatCurrency(totalDebtBalance) : '--',
        subtitle: plan?.debts.length
          ? `Starting from ${formatCurrency(totalStartingBalance)} in ${plan.debts.length} debt${plan.debts.length === 1 ? '' : 's'}`
          : '--',
        href: `/plans/${plan?.id}?tab=debts`,
      },
      {
        id: 'interest',
        Icon: IconSquareRoundedPercentage,
        title: 'Total debt interest',
        value:
          stats.totalInterest || stats.totalInterest === 0
            ? formatCurrency(stats.totalInterest)
            : '--',
        subtitle:
          stats.totalPayments || stats.totalPayments === 0
            ? `Projected after ${stats.totalPayments.toLocaleString()} payments`
            : '--',
        href: `/plans/${plan?.id}?tab=debts`,
      },
    ],
    [
      plan?.debts,
      totalDebtBalance,
      totalStartingBalance,
      stats.totalInterest,
      stats.totalPayments,
    ]
  );

  return <StatsCards items={items} />;
}
