import { Alert, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

export function Disclaimer({ children }: { children: React.ReactNode }) {
  return (
    <Alert
      icon={<IconAlertCircle size="2rem" />}
      color="blue"
      variant="light"
      w="100%"
      py="xs"
      styles={{
        body: {
          justifyContent: 'center',
        },
      }}
    >
      <Text size="xs" c="blue.9" pos="relative" top={1}>
        {children}
      </Text>
    </Alert>
  );
}
