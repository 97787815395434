import React from 'react';

const Star = ({ color = 'white', ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.54688 1.78125C5.73438 1.40625 6.26562 1.42188 6.4375 1.78125L7.46875 3.85938L9.75 4.1875C10.1562 4.25 10.3125 4.75 10.0156 5.04688L8.375 6.65625L8.76562 8.92188C8.82812 9.32812 8.39062 9.64062 8.03125 9.45312L6 8.375L3.95312 9.45312C3.59375 9.64062 3.15625 9.32812 3.21875 8.92188L3.60938 6.65625L1.96875 5.04688C1.67188 4.75 1.82812 4.25 2.23438 4.1875L4.53125 3.85938L5.54688 1.78125Z"
      fill={color}
    />
  </svg>
);

export default Star;
