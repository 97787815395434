import React from 'react';

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#fff"
        d="M21.781 18.344a.467.467 0 01.094.687l-.594.782c-.187.218-.5.28-.718.093L2.188 5.688A.468.468 0 012.094 5l.625-.781a.468.468 0 01.687-.094l4.75 3.656L9.72 6.25c.469-.469 1.281-.156 1.281.531V9.97l2.469 1.937a1.234 1.234 0 00-.625-.969.773.773 0 01-.313-1.03c.219-.376.656-.5 1.031-.282A2.739 2.739 0 0115 12c0 .344-.094.625-.188.938l1.22.937c.28-.563.468-1.188.468-1.875a4.26 4.26 0 00-2-3.594.751.751 0 01-.219-1.031c.219-.344.688-.469 1.031-.25C16.97 8.188 18 10.031 18 12a5.727 5.727 0 01-.75 2.813l1.188.937c.656-1.125 1.03-2.406 1.03-3.719 0-2.469-1.218-4.781-3.312-6.125-.343-.218-.437-.687-.218-1.062A.795.795 0 0117 4.625c2.5 1.656 4 4.406 4 7.375 0 1.688-.5 3.281-1.375 4.656l2.156 1.688zM3 9.75c0-.25.125-.469.344-.625L11 15.031v2.219c0 .688-.813 1-1.281.531L6.938 15H3.75a.722.722 0 01-.75-.75v-4.5z"
      ></path>
    </svg>
  );
}

export default Icon;
