import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import Button, { PrimaryButton } from '~/core/components/Button';
import { Plus } from '~/icons';

export const ExtraActionContainer = styled.div`
  border: 1px solid #edeef2;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.system.background};
  align-self: flex-start;
  padding: 16px;
  flex-shrink: 0;
  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 48em) {
    border-radius: 0;
    border-right: none;
    border-left: none;
  }
`;

export const ExtraActionContainerHeader = styled.div`
  ${({ theme }) => theme.typography.title};
  line-height: 24px;
`;

export const ExtraActionContainerBody = styled.div`
  ${({ theme }) => theme.typography.body};
  line-height: 20px;
`;

export const Footer = styled.div`
  margin-top: 16px;
`;

export const ExtraActionPrimaryButton = styled(PrimaryButton)`
  padding: 10px 16px;
  justify-content: center;
  width: 100%;
`;

export const ExtraActionButton = styled(Button)<{ $destructive?: boolean }>`
  padding: 10px 16px;
  justify-content: center;
  width: 100%;

  ${({ $destructive: destructive, theme }) =>
    destructive &&
    css`
      color: ${theme.palette.alert.main};
      border-color: ${theme.palette.alert.main};

      &:hover {
        background: ${theme.palette.alert.shade3};
      }
    `}
`;

export const PlusIcon = styled(Plus).attrs<{ icon?: ReactNode }>({ width: 15, height: 15 })`
  margin-right: 8px;
`;
