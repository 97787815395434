'use client';

import Image from 'next/image';
import { cn, env } from '@/utils';
import {
  Box,
  Button,
  Container,
  Flex,
  Group,
  Stack,
  Text,
  Title,
  rem,
} from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { IconCircleCheckFilled } from '@tabler/icons-react';
import { LegalFooter } from './LegalFooter';
import { FinexLogo } from './FinexLogo';
import { ArrowUp } from './ArrowUp';
import { GradientText } from './GradientText';
import { DrawnSeparator } from './DrawnSeparator';
import { InstantLink } from './InstantLink';

const FEATURES = [
  {
    header: (
      <>
        Get a <GradientText>personalized</GradientText> debt&#8209;free plan,{' '}
        instantly.
      </>
    ),
    description: (
      <>
        Simply enter your debts, and like magic, Finex generates a step-by-step
        plan tailored specifically for you. No guesswork. Just a clear,
        optimized roadmap to your debt&#8209;free life.
      </>
    ),
    points: [
      <>Know exactly how much to pay next.</>,
      <>Pay off each debt as fast as possible.</>,
      <>Save a ton of money in interest along the way.</>,
    ],
    imageUrl: '/assets/images/home/homepage-checklist.svg',
    imageAspectRatio: 1024 / 1140,
    imageAlt: 'A checklist showing the steps to follow to get debt-free.',
  },
  {
    header: (
      <>
        Know the <GradientText>exact&nbsp;day</GradientText> you’ll be
        debt&#8209;free.
      </>
    ),
    description: (
      <>
        You'll get your own debt&#8209;free countdown, allowing you to see the
        number of days until you have zero debt get lower each day. That's a
        powerful feeling.
      </>
    ),
    points: [
      <>Always see when your debt&#8209;free day is coming.</>,
      <>Stay motivated as the countdown ticks down.</>,
      <>See the impact of your extra payments.</>,
    ],
    imageUrl: '/assets/images/home/homepage-countdown.svg',
    imageAspectRatio: 1024 / 1140,
    imageAlt: 'A countdown showing the number of days until you are debt-free.',
  },
  {
    header: (
      <>
        Reach zero debt much <GradientText>faster</GradientText> than doing it
        alone.
      </>
    ),
    description: (
      <>
        Following a structured debt payoff plan significantly speeds up the
        process of getting debt&#8209;free faster than those who go at it
        blindly. Often times, years sooner.
      </>
    ),
    points: [
      <>Have confidence in a proven strategy that works.</>,
      <>Step-by-step guidance keeps you focused.</>,
      <>No more making random payments & hoping for the best.</>,
    ],
    imageUrl: '/assets/images/home/homepage-fast.svg',
    imageAspectRatio: 1024 / 1207,
    imageAlt: 'A diagram showing getting debt-free faster.',
  },
  {
    header: (
      <>
        Save <GradientText>thousands</GradientText> in interest payments on
        average.
      </>
    ),
    description: (
      <>
        With your Finex debt-free plan, you'll pay the least interest possible.
        Households who follow optimized payoff strategies can save thousands per
        year in interest on average.
      </>
    ),
    points: [
      <>Pay debts in the right order to maximize savings.</>,
      <>Cut years off your debt repayment timeline.</>,
      <>Keep more money in your pocket instead of losing it in interest.</>,
    ],
    imageUrl: '/assets/images/home/homepage-savings.svg',
    imageAspectRatio: 1024 / 1140,
    imageAlt:
      'A diagram showing how saving thousands in interest payments can help you get debt-free faster.',
  },
  {
    header: (
      <>
        Easily track your <GradientText>progress</GradientText> on every billing
        period.
      </>
    ),
    description: (
      <>
        You can add debt updates as you make payments, and your plan will
        automatically adjust to keep you on the fastest path to debt freedom.
      </>
    ),
    points: [
      <>Quick updates ensure you’re always on track.</>,
      <>Effortless to manage with minimal hassle.</>,
      <>Your plan adapts when life happens.</>,
    ],
    imageUrl: '/assets/images/home/homepage-flowchart.svg',
    imageAspectRatio: 1024 / 994,
    imageAlt: 'A person updating their plan.',
  },
  {
    header: (
      <>
        Join a{' '}
        <span>
          <GradientText className="hidden sm:inline">
            debt&#8209;free community
          </GradientText>
          <span className="inline sm:hidden">
            <GradientText>debt&#8209;free</GradientText>
            <br className="block sm:hidden" />
            <GradientText>community</GradientText>
          </span>
        </span>{' '}
        that's in this with you.
      </>
    ),
    description: (
      <>
        You're not alone. Join others on their debt&#8209;free journey inside
        the supportive communities.
      </>
    ),
    points: [
      <>
        Connect with others who understand the challenges of becoming
        debt&#8209;free.
      </>,
      <>Ask questions and receive support from people on a similar path.</>,
      <>
        Share your progress, setbacks, and insights in a judgment-free space.
      </>,
      // <>Get real-life tips & advice along your journey.</>,
      // <>Celebrate wins and share struggles, all judgment-free.</>,
      // <>Get inspired by success stories from fellow members.</>,
    ],
    imageUrl: '/assets/images/home/homepage-social.svg',
    imageAspectRatio: 1024 / 1028,
    imageAlt: 'A posting in the Finex community.',
  },
  {
    header: (
      <>
        Stay <GradientText>motivated</GradientText> with shareable
        debt&#8209;free countdowns.
      </>
    ),
    description: (
      <>
        You can see other community member's debt&#8209;free countdowns next to
        their names, and have the option to share your own. It's a great
        motivator for getting debt&#8209;free fast.
      </>
    ),
    points: [
      <>Inspire yourself and others to stay on track.</>,
      <>Hold yourself accountable for reaching zero debt.</>,
      <>Actual debt details are always kept private.</>,
    ],
    imageUrl: '/assets/images/home/homepage-party.svg',
    imageAspectRatio: 1024 / 1012,
    imageAlt: 'Two people celebrating their wins on their debt-free journeys.',
  },
];

export function HomeLoggedOut() {
  const [scroll] = useWindowScroll();

  return (
    <>
      <Box
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: rem(60),
          transform: `translateY(${scroll.y > 450 ? 0 : -100}px)`,
          transition: 'transform 300ms ease',
          backgroundColor: 'white',
          boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)',
          zIndex: 1000,
        }}
      >
        <Container h="100%">
          <Flex
            justify="space-between"
            align="center"
            h="100%"
            style={{
              paddingLeft: 'var(--safe-area-left)',
              paddingRight: 'var(--safe-area-right)',
            }}
          >
            <Group gap="xs">
              <InstantLink href="/">
                <Group gap={8} wrap="nowrap">
                  <FinexLogo size={40} />
                  <Title order={3}>Finex</Title>
                </Group>
              </InstantLink>
            </Group>
            <Button size="sm" component={InstantLink} href="/start">
              {env.NEXT_PUBLIC_FREE_TRIAL_DAYS_COUNT ? (
                <>Start for free</>
              ) : (
                <>Get started now</>
              )}
            </Button>
          </Flex>
        </Container>
      </Box>

      <Container
        h="100%"
        p={0}
        className="ml-[var(--safe-area-left)] md:ml-auto"
      >
        <Container mih="100dvh" p={0}>
          <Group justify="space-between" py="md" className="mb-10" px="md">
            <Group gap="xs">
              <InstantLink href="/">
                <Group gap="xs" wrap="nowrap">
                  <FinexLogo size={40} />
                  <Title order={3}>Finex</Title>
                </Group>
              </InstantLink>
            </Group>
            <Button
              variant="light"
              size="sm"
              component={InstantLink}
              href="/login"
            >
              Log in
            </Button>
          </Group>

          <Stack className="!gap-24 sm:!gap-32">
            <Stack align="center" justify="center" gap="xl">
              <Stack gap="lg" ta="center" align="center">
                <Stack align="center" gap="xs">
                  <h1 className="mt-3 sm:text-[4.5rem] text-[3.8rem] font-extrabold leading-[1.1]">
                    Let's Get
                    <br />
                    Debt&#8209;Free. <br className="block sm:hidden" />
                    <GradientText>Faster.</GradientText>
                  </h1>
                </Stack>
                <Text size="lg" maw={{ base: 280, xs: 425 }} mt="xs">
                  Finex is the debt planner & community platform that helps you
                  get <b>debt&#8209;free&nbsp;faster</b>.
                </Text>
                <Group justify="center" my="md" pos="relative" mb={20}>
                  <Button
                    size="xl"
                    className="from-blue-600 to-blue-400 qbg-gradient-to-r"
                    component={InstantLink}
                    href="/start"
                  >
                    {env.NEXT_PUBLIC_FREE_TRIAL_DAYS_COUNT ? (
                      <>Start for free</>
                    ) : (
                      <>Get started now</>
                    )}
                  </Button>
                  <ArrowUp
                    size={90}
                    className="absolute right-[-40px] bottom-[-85px] rotate-[-30deg] arrow-up-animation"
                  />
                </Group>
              </Stack>
            </Stack>

            <Group align="center" justify="center" ta="center" mt="md">
              <h2 className="text-[2.8rem] sm:text-[3.2rem] font-bold leading-[1.1] max-w-[22rem] sm:max-w-[38rem]">
                Finally feel
                <br />{' '}
                <GradientText className="whitespace-nowrap">
                  peace of mind
                </GradientText>
                <br /> about your debt.
              </h2>
            </Group>

            <DrawnSeparator />

            <Stack className="!gap-20 sm:!gap-28">
              {FEATURES.map((feature, i) => {
                return (
                  <Stack key={i}>
                    {i !== 0 ? (
                      <DrawnSeparator className="mb-12 sm:mb-20" />
                    ) : null}

                    <Group justify="center">
                      <Flex
                        className={cn(
                          'flex-col sm:flex-row gap-3 sm:gap-16 items-center',
                          'max-w-[26rem] sm:max-w-none',
                          {
                            'sm:flex-row-reverse': i % 2 === 0,
                          }
                        )}
                      >
                        <Box
                          className="relative flex-1 sm:w-full w-[70%] rounded-lg aspect-square hidden sm:block"
                          style={{
                            aspectRatio: feature.imageAspectRatio,
                          }}
                        >
                          {feature.imageUrl && (
                            <Image
                              alt={feature.imageAlt}
                              src={feature.imageUrl}
                              fill
                              className="object-contain object-center w-full h-full animate-wiggle-more"
                            />
                          )}
                        </Box>
                        <Stack gap="xs" flex={1.5} px="xs">
                          <Title
                            order={2}
                            size={rem(36)}
                            className="text-center sm:text-left leading-[1.2]"
                          >
                            {feature.header}
                          </Title>
                          <Group
                            justify="center"
                            className="my-5 w-full sm:!hidden"
                          >
                            <Box
                              className="relative w-[70%] rounded-lg aspect-square"
                              style={{
                                aspectRatio: feature.imageAspectRatio,
                              }}
                            >
                              {feature.imageUrl && (
                                <Image
                                  alt={feature.imageAlt}
                                  src={feature.imageUrl}
                                  fill
                                  className="object-contain object-center w-full h-full animate-wiggle-more"
                                />
                              )}
                            </Box>
                          </Group>
                          <Text size="lg">{feature.description}</Text>
                          <Stack mt="xs" gap="xs">
                            {feature.points.map((point, i2) => (
                              <Group
                                key={i2}
                                gap="xs"
                                align="start"
                                wrap="nowrap"
                              >
                                <IconCircleCheckFilled
                                  className="mt-px text-green-600 shrink-0"
                                  size={26}
                                />
                                <Text size="lg">{point}</Text>
                              </Group>
                            ))}
                          </Stack>
                        </Stack>
                      </Flex>
                    </Group>
                  </Stack>
                );
              })}
            </Stack>

            <DrawnSeparator />

            <Stack align="center" ta="center" className="pb-10">
              <Title
                order={2}
                size={rem(36)}
                maw={500}
                className="leading-[1.2]"
              >
                Your faster path to getting debt&#8209;free{' '}
                <GradientText className="pr-px">starts&nbsp;today</GradientText>
                .
              </Title>
              <Button
                size="lg"
                className="mt-8 from-blue-600 to-blue-400"
                component={InstantLink}
                href="/start"
              >
                {env.NEXT_PUBLIC_FREE_TRIAL_DAYS_COUNT ? (
                  <>Start for free</>
                ) : (
                  <>Get started now</>
                )}
              </Button>
              <LegalFooter />
            </Stack>
          </Stack>
        </Container>
      </Container>
    </>
  );
}
