import React from 'react';

const Svg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.1875 0.375H1.8125C0.863281 0.375 0.125 1.14844 0.125 2.0625V14.4375C0.125 15.3867
      0.863281 16.125 1.8125 16.125H14.1875C15.1016 16.125 15.875 15.3867 15.875
      14.4375V2.0625C15.875 1.14844 15.1016 0.375 14.1875 0.375ZM14.1875
      14.4375H1.8125V2.0625H14.1875V14.4375ZM4.34375 12.75H4.90625C5.1875 12.75 5.46875 12.5039
      5.46875 12.1875V7.6875C5.46875 7.40625 5.1875 7.125 4.90625 7.125H4.34375C4.02734 7.125
      3.78125 7.40625 3.78125 7.6875V12.1875C3.78125 12.5039 4.02734 12.75 4.34375 12.75ZM7.71875
      12.75H8.28125C8.5625 12.75 8.84375 12.5039 8.84375 12.1875V4.3125C8.84375 4.03125 8.5625
      3.75 8.28125 3.75H7.71875C7.40234 3.75 7.15625 4.03125 7.15625 4.3125V12.1875C7.15625
      12.5039 7.40234 12.75 7.71875 12.75ZM11.0938 12.75H11.6562C11.9375 12.75 12.2188 12.5039
      12.2188 12.1875V9.9375C12.2188 9.65625 11.9375 9.375 11.6562 9.375H11.0938C10.7773 9.375
      10.5312 9.65625 10.5312 9.9375V12.1875C10.5312 12.5039 10.7773 12.75 11.0938 12.75Z"
      fill="#292B32"
    />
  </svg>
);

export default Svg;
