import { Disclaimer } from './Disclaimer';

export function DebtUpdateDisclaimer() {
  return (
    <Disclaimer>
      For accuracy, make sure these values match your actual billing statement
      for this period.
    </Disclaimer>
  );
}
