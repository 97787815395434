'use client';

import { useRouter } from 'next/navigation';
import { Button } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';

export function BackButton({ fallbackUrl = '/' }: { fallbackUrl?: string }) {
  const router = useRouter();
  return (
    <Button
      variant="light"
      radius="xl"
      p="xs"
      mb="lg"
      onClick={() => {
        if (window.history?.length && window.history.length > 1) {
          router.back();
        } else {
          router.replace(fallbackUrl);
        }
      }}
    >
      <IconArrowLeft size={14} />
    </Button>
  );
}
