import { AmityUiKitProviderDirect } from 'finex-amity-uikit';
import { env } from '@/utils';
import { useFinex } from '@/hooks';

export function AmityProvider({
  children,
}: {
  readonly children?: React.ReactNode;
}): JSX.Element | null {
  const { user, amityState } = useFinex();

  if (!user.data?.id || !amityState.isProviderNeeded) {
    return <>{children}</>;
  }

  return (
    <AmityUiKitProviderDirect
      apiEndpoint={{ http: 'https://api.us.amity.co' }}
      apiRegion="us"
      apiKey={env.NEXT_PUBLIC_AMITY_API_KEY}
      userId={user.data?.id}
      onIsConnectedChange={(isConnected: boolean) => {
        setTimeout(() => amityState.setIsProviderConnected(isConnected), 100);
      }}
    >
      {children}
    </AmityUiKitProviderDirect>
  );
}
