'use client';

import posthog from 'posthog-js';
import * as PostHogReact from 'posthog-js/react';
import { useEffect } from 'react';
import { env } from '@/utils';

export function PostHogProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  useEffect(() => {
    if (typeof window === 'undefined') return;
    // Only initialize if not already initialized
    if (!posthog.__loaded) {
      const isProd = window.location.hostname === 'finex.money';
      const token = isProd ? env.NEXT_PUBLIC_POSTHOG_KEY : 'dev-token';
      // const isProd = true;
      // const token = env.NEXT_PUBLIC_POSTHOG_KEY;
      if (isProd) {
        posthog.init(token, {
          api_host: '/ingest',
          ui_host: env.NEXT_PUBLIC_POSTHOG_HOST,
          capture_pageview: false, // Disable automatic pageview capture, as we capture manually
          capture_pageleave: isProd,
          autocapture: isProd,
          loaded: (ph) => {
            if (!isProd) {
              ph.debug();
              ph.opt_out_capturing(); // opts a user out of event capture
              ph.set_config({ disable_session_recording: true });
            }
          },
        });
      }
    }
  }, []); // Run once on mount

  return (
    <PostHogReact.PostHogProvider client={posthog}>
      {children}
    </PostHogReact.PostHogProvider>
  );
}
